import { FC, useEffect, useRef, useState } from "react"
import useDevice from "../hooks/useDevice";
import { useAccount, useNetwork } from "wagmi";
import toast, { Toaster } from "react-hot-toast";
import { writeContract, readContract, waitForTransaction } from "@wagmi/core";
import { OPENSEA_API_KEY, SUPPORT_CHAIN_ID, ZERO_ADDRESS, getNetwork } from "../config";
import { NetworkData } from "../utils/types";
import { formatAddress, getErrorMessage, parseQueryDeployData, toNumber } from "../utils/common";
import LinkTo from "./LinkTo";
import { BigNumber, ethers } from "ethers";
import { useParams } from 'react-router-dom';
import BridgeABI from "../abi/Ferc721Bridge.json";
import ERC20ABI from "../abi/FERC20.json";
import FERC721ABI from "../abi/FERC721.json";
import { queryDeployDetailsByTick, queryMintByHolderAndInscriptionAddressAll } from "../utils/graphQuery";
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { IoRefresh } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";

interface BridgeProps {
	graphClient: ApolloClient<NormalizedCacheObject>;
}

interface TokenId {
	id: number;
	selected: boolean;
}

const Bridge:FC<BridgeProps> = ({
	graphClient
}) => {
	const params = useParams();
	const [tick, setTick] = useState(params.tick === undefined ? "" : params.tick);
	const [imprintAddress, setImprintAddress] = useState("");
	const [gettingInfo, setGettingInfo] = useState(false);
	const [amount, setAmount] = useState("");
	const [limit, setLimit] = useState(0);
	const [tokenIds, setTokenIds] = useState("");
	const [selectedTokenIds, setSelectedTokenIds] = useState(Array<number>);
	const [erc20Balance, setErc20Balance] = useState(BigNumber.from(0));
	const [network, setNetwork] = useState({} as NetworkData);
	const [depositing, setDepositing] = useState(false);
	const [withdrawing, setWithdrawing] = useState(false);
	const [erc20Address, setErc20Address] = useState("");
	const [mintFinished, setMintFinished] = useState(0); // 0: checking, 1- not finished, 2- finished

	const isMobile = useDevice();

	const { chain } = useNetwork();
	const { address: currentAddress } = useAccount();

	const abortSignalRef = useRef(new AbortController());

	useEffect(() => {
		if(tokenIds !== "") {
			const _tokenIds = JSON.parse(tokenIds);
			const ids = [];
			for(let i = 0; i < _tokenIds.length; i++) {
				if(_tokenIds[i].selected) ids.push(_tokenIds[i].id);
			}
			setSelectedTokenIds(ids);
		}
	}, [tokenIds]);

	useEffect(() => {
		if (chain) {
			const chainId = chain.id;
			if (chainId !== SUPPORT_CHAIN_ID) {
				toast.error("Only support chain id: " + SUPPORT_CHAIN_ID);
				return;
			}
			const _network = getNetwork(chainId);
			setNetwork({
				id: chainId,
				name: chain.name,
				symbol: chain.nativeCurrency.symbol,
				decimals: chain.nativeCurrency.decimals,
				scanUrl: _network?.scanUrl as string,
				contractAddress: _network?.contractAddress as string,
				wethAddress: _network?.wethAddress as string,
				fercAddress: _network?.fercAddress as string,
				bridgeContract: _network?.bridgeContract as string,
				rpc: _network?.rpc as string,
				bgColor: _network?.bgColor as string,
				foreColor: _network?.foreColor as string,
				openseaApi: _network?.openseaApi as string,
			} as NetworkData)
			if (_network === undefined) toast.error("Network loaded fail");
			
			if(params.tick !== "" && params.tick !== undefined) getInfo(_network?.bridgeContract as string, params.tick);
		}
	}, [chain, currentAddress, tick]);

	const getInfo = async (bridgeContract: string, tick: string) => {
		if(tick === "") return;
		try {
			setGettingInfo(true);
			// queryDeployDetailsByTick 
			const tokenData = await graphClient.query({
				query: gql`${queryDeployDetailsByTick}`,
				variables: {
					tick,
				},
				context: {
					fetchOptions: {
						get signal() {
							return abortSignalRef.current.signal;
						},
					}
				}
			});
			// abortSignalRef.current.abort();

			// console.log(tokenData);
			if (tokenData.error !== undefined || tokenData.data.deploys[0] === undefined) {
				toast.error("Unavailable tick: " + tick);
				setGettingInfo(false);
				return;
			}
			const parsedData = parseQueryDeployData(tokenData.data.deploys[0]);
			const _imprintAddress = parsedData.inscriptionAddress;
			setMintFinished(parsedData.completed ? 2 : 1);
			setAmount("");

			if(!parsedData.completed) {
				setGettingInfo(false);
				return;
			}

			setImprintAddress(_imprintAddress);
			const _erc20Address = await readContract({
				address: bridgeContract as `0x{string}`,
				abi: BridgeABI,
				functionName: 'ferc20Addresses',
				args: [_imprintAddress]
			}) as any;
			setErc20Address(_erc20Address as string);	
			setLimit(toNumber(parsedData.limit));

			// get erc20 balance
			if(_erc20Address !== ZERO_ADDRESS) {
				const _erc20Balance: any = await readContract({
					address: _erc20Address as `0x{string}`,
					abi: ERC20ABI,
					functionName: "balanceOf",
					args: [currentAddress]
				})
				setErc20Balance(BigNumber.from(_erc20Balance));	
			}

			const result = await graphClient.query({
				query: gql`${queryMintByHolderAndInscriptionAddressAll}`,
				variables: {
					owner: currentAddress,
					tick: tick,
				},
				context: {
					fetchOptions: {
						get signal() {
							return abortSignalRef.current.signal;
						},
					}
				}
			});
			// abortSignalRef.current.abort();

			if (result.error !== undefined || result.data.mints[0] === undefined) {
				// toast.error("NO MORE");
				setTokenIds("");
				setSelectedTokenIds([]);
				setGettingInfo(false);
				return
			}
			const list = Array<TokenId>();
			for(let i = 0; i < result.data.mints.length; i++) {
				list.push({
					id: result.data.mints[i].tokenId,
					selected: false,
				});
			}
			// console.log(JSON.stringify(list));
			setTokenIds(JSON.stringify(list));
			setGettingInfo(false);
		} catch (err:any) {
			setGettingInfo(false);
			// console.log(err);
			toast.error(getErrorMessage(err.message));
		}
	}

	const deposit = async () => {
		if(tick === "" || selectedTokenIds.length === 0 || imprintAddress === "") {
			toast.error("Please set tick and token id");
			return;
		}

		try {
			setDepositing(true);
			// check if approval
			const approval = await readContract({
				address: imprintAddress as `0x{string}`,
				abi: FERC721ABI,
				functionName: "isApprovedForAll",
				args: [currentAddress, network.bridgeContract]
			})

			if(!approval) {
				const { hash } = await writeContract({
					address: imprintAddress as `0x{string}`,
					abi: FERC721ABI,
					functionName: "setApprovalForAll",
					args: [network.bridgeContract, true]
				})
				waitForTransaction({hash}).then(async (data) => {
					if(data.status === 'success') {
						toast.success("Approve successfully");
					} else {
						toast.error("Approve Error");
					}
					setDepositing(false);
				})
			}
			// console.log("====1=====", network.bridgeContract, imprintAddress, selectedTokenIds)

			graphClient.clearStore();
			const {hash} = await writeContract({
				address: network.bridgeContract as `0x{string}`,
				abi: BridgeABI,
				functionName: 'batchDeposit',
				args: [imprintAddress, selectedTokenIds]
			})

			waitForTransaction({hash}).then(async (data) => {
				if(data.status === 'success') {
					toast.success("Deposit successfully");
					setGettingInfo(true);
	
					for(let i = 0; i < selectedTokenIds.length; i++) {
						refreshOpenseaMetadata(selectedTokenIds[i]);
					}
	
					const interval = setInterval(async () => {
						clearInterval(interval);
						getInfo(network.bridgeContract as string, tick);
					}, 5000);
				} else {
					toast.error("Deposit Error");
				}
				setDepositing(false);
			})

		} catch (err: any) {
			console.log(err.message);
			toast.error(getErrorMessage(err.message));
			setDepositing(false);
		}
	}
	
	const withdraw = async () => {
		if(erc20Address === "" || amount === "") {
			toast.error("Please input erc20 address and withdraw amount");
			return;
		}

		if(parseInt(amount) % toNumber(limit) > 0) {
			toast.error("Withdraw amount must be a multiple of limit");
			return;
		}
		try {
			setWithdrawing(true);
			// approve the bridge contract to use
			const weiAmount = ethers.utils.parseUnits(amount, 18);
			const allowance = await readContract({
				address: erc20Address as `0x{string}`,
				abi: ERC20ABI,
				functionName: "allowance",
				args: [currentAddress, network.bridgeContract]
			})

			// console.log("allowance", allowance, "amount", weiAmount.toString());
			// console.log(BigInt(allowance as string) < (BigInt(amount)));
			if(BigNumber.from(allowance).lt(weiAmount)) {
				// approve 
				toast.success("Approving the bridge contract");
				const { hash } = await writeContract({
					address: erc20Address as `0x{string}`,
					abi: ERC20ABI,
					functionName: "approve",
					args: [network.bridgeContract, weiAmount.mul(10)],
				})

				const data = await waitForTransaction({hash});
				if(data.status === 'success') {
					toast.success("Approve successfully");
				} else {
					toast.error("Approve Error");
					setWithdrawing(false);
					return;
				}
			}

			// 操作withdraw
			graphClient.clearStore();
			const { hash } = await writeContract({
				address: network.bridgeContract as `0x{string}`,
				abi: BridgeABI,
				functionName: 'withdraw',
				args: [erc20Address, weiAmount],
			})

			waitForTransaction({hash}).then(async (data) => {
				// console.log(data);
				if(data.status === 'success') {
					toast.success("Withdraw successfully");

					const tokenIds = getWithdrawTokenIds(data.logs);
					for(let i = 0; i < tokenIds.length; i++) {
						refreshOpenseaMetadata(tokenIds[i]);
					}

					setGettingInfo(true);
					const interval = setInterval(async () => {
						clearInterval(interval);
						getInfo(network.bridgeContract as string, tick);

					}, 5000);
				} else {
					toast.error("Withdraw Error");
				}
				setWithdrawing(false);
			})

		} catch (err: any) {
			// console.log(err.message);
			toast.error(getErrorMessage(err.message));
			setWithdrawing(false);
		}
	}

	const getWithdrawTokenIds = (logs: Array<any>) => {
		const tokenIds = [];
		for(let i = 0; i < logs.length; i++) {
			const log = logs[i];
			if(log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef" 
				&& log.topics[1].toLowerCase() === toLongAddress(network.bridgeContract as string).toLowerCase()
				&& log.topics[2].toLowerCase() === toLongAddress(currentAddress as string).toLowerCase()) {
				const tokenId = log.topics[3];
				tokenIds.push(parseInt(tokenId, 16));
			}
		}
		return tokenIds;
	}

	const toLongAddress = (address: string) => {
		return "0x000000000000000000000000" + address.substring(2);
	}

	const refreshOpenseaMetadata = (tokenId: number) => {
		// refresh the metadata in opensea
		const options = {method: 'POST', headers: {'x-api-key': OPENSEA_API_KEY}};

		fetch(`${network.openseaApi}/contract/${imprintAddress}/nfts/${tokenId}/refresh`, options)
			.then(response => response.json())
			.then(response => console.log(response))
			.catch(err => console.error(err));
	}

	return(
		<div className={`${isMobile ? "mt-20" : "mt-8"} w-full md:p-24 p-2 text-center`}>
			<div>
				<div 
					className={`flex justify-end mr-5 cursor-pointer`} 
					onClick={() => getInfo(network.bridgeContract as string, tick)}
				>
					<IoRefresh className={`${gettingInfo ? "loading-icon" : ""}`}/>
				</div>
				<div className="font-bold">From Ferc721 to Ferc20</div>
				<div className="flex justify-center input-group">
					<input
						type="text"
						placeholder="tick name"
						value={tick}
						className="input input-bordered max-w-xs mt-3"
						onKeyUp={(e) => {if(e.key === 'Enter') getInfo(network.bridgeContract as string, tick)}}
						onChange={(e) => setTick(e.target.value)}
						onBlur={(e) => getInfo(network.bridgeContract as string, tick)}
					/>
					<button
						className="btn btn-md text-base-content mt-3"
						onClick={() => getInfo(network.bridgeContract as string, tick)}
					>
						<FiSearch className='text-xl' />
					</button>
				</div>
			</div>

			{mintFinished === 2 ? 
			<div className="w-[88%] ml-[6%] text-sm mt-10">
				{erc20Address !== "" && erc20Address !== ZERO_ADDRESS && 
					<div className="flex justify-start mt-3 text-sm">
						{"Ferc20 Token: " + formatAddress(erc20Address)}
						<LinkTo copyText={erc20Address} url={network.scanUrl + "/address/" + erc20Address}/>
					</div>}

				{limit > 0 && <div>
					<div className="flex justify-start mb-3 mt-3 text-sm">
						{"Limit: " + limit.toString() + "/mint"}
					</div>
				</div>}
				
				{tokenIds !== "" && <div>
					<div className="mt-3 text-left">You have the following Ferc721 token ids, select and press Deposit button to swap to Ferc20 tokens.</div>
					<div className="flex flex-row flex-wrap gap-2 justify-start mb-3 mt-3">
						{tokenIds !== "" && (JSON.parse(tokenIds) as Array<TokenId>).map((tokenId: TokenId, index) => 
						<div key={index} className={`${tokenId.selected ? "bg-primary":""} border-2 border-primary px-3 rounded-md cursor-pointer`} onClick={(e) => {
							const _tokenIds = JSON.parse(tokenIds) as Array<TokenId>;
							_tokenIds[index].selected = !_tokenIds[index].selected;
							setTokenIds(JSON.stringify(_tokenIds));
						}}>
							{tokenId.id}
						</div>)}					
					</div>
					{/* <input type="hidden"
						placeholder="token id"
						value={selectedTokenIds.join(",")}
						className="input input-bordered w-full max-w-xs my-3 mr-2"
						onChange={(e) => {}}
					/> */}
					<button
						className={`btn btn-outline btn-md m-auto ${depositing ? "loading btn-disabled" : "btn-secondary"}`}
						onClick={() => deposit()}
					>
						Deposit
					</button>
				</div>}

				<div className="divider"/>

				<div>
					<div className="font-bold">From Ferc20 to Ferc721</div>
					<div className="text-center mt-5 mb-3 text-sm">
						{"Ferc20 balance: " + ethers.utils.formatUnits(erc20Balance, 18)}
					</div>
					<input
						type="text"
						placeholder="Withdraw amount"
						value={amount}
						className="input input-bordered max-w-xs my-3"
						onKeyUp={(e) => { if (e.key === "Enter") withdraw(); }}
						onChange={(e) => setAmount(e.target.value)}
					/>
					<button
						className={`btn btn-outline btn-secondary btn-md ${withdrawing ? "loading btn-disabled" : ""}`}
						onClick={() => withdraw()}
					>
						Withdraw
					</button>
				</div>
			</div>
			: mintFinished === 1 &&
			<div className="w-[88%] ml-[6%] text-sm mt-3 text-error">
				{"Mint has not finished yet, can not swap between ferc721 and ferc20 tokens"}
			</div>}

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 2000,
			}} />

		</div>
	)
}

export default Bridge