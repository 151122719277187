import { FC, useEffect, useState } from 'react';
import Logo from './Logo';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { DARK_THEME, LIGHT_THEME, OPENSEA_URL, SUPPORT_CHAIN_ID } from '../config';
import { formatAddress } from '../utils/common';
import { Link } from "react-router-dom";
import Login from './Login';
import { IoSunnyOutline, IoMoonOutline, IoHome } from "react-icons/io5";
import { SiOpensea } from "react-icons/si";
import { MdDataObject, MdLogout } from "react-icons/md";
import { TbBuildingBridge2 } from "react-icons/tb";
import { useStore } from '../hooks/useStore';
import { FaChevronDown } from "react-icons/fa";

interface HeadProps {
	defaultConnector: number;
}

const Header: FC<HeadProps> = ({
	defaultConnector
}) => {
	// const [showAddressBadge, setShowAddressBadge] = useState(false);
	const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : DARK_THEME);
	const { address, isConnected } = useAccount();
	const { disconnect } = useDisconnect();
	const setGlogbalTheme = useStore((state) => state.setTheme);
	const { chain } = useNetwork();

	useEffect(() => {
		localStorage.setItem("theme", theme as string);
		document.querySelector("html")?.setAttribute("data-theme", theme as string);
	}, [theme]);

	const handleToggle = () => {
		const _theme = theme === DARK_THEME ? LIGHT_THEME : DARK_THEME;
		setTheme(_theme);
		setGlogbalTheme(_theme);
	}

	const closeDropdown = () => {
		const elem = document.activeElement as HTMLElement;
		if (elem) {
			elem?.blur();
		}
	};

	return (
		<>
			<div className="navbar md:py-5 md:px-10 p-3 px-6 bg-base-200 fixed top-0 lef-0 right-0">
				{/* <div className='bg-orange-500 top-0 w-full rounded-lg text-black p-3'>Cirth.meme preview version is online. NOTE: it's not ethereum mainnet, all NFT and Tokens is no value, even it is on Polygon mainnet.</div> */}
				<div className="flex-1">
					<Link to="/"><Logo /></Link>
				</div>
				<div className="dropdown dropdown-end">
					<label>
						{isConnected ?
							<label tabIndex={0}>
								{/* <div className="avatar w-[32px] md:w-[40px] mt-1 mask mask-squircle"> */}
								{chain?.id === SUPPORT_CHAIN_ID ? 
								<div>
									<div className="flex border-2 border-primary rounded-lg px-3 py-1 text-sm hover:bg-primary">
										{formatAddress(address as string, 5)}
										<FaChevronDown className='mt-1 ml-2'/>
									</div>
									<ul tabIndex={0} className="p-2 shadow menu menu-compact dropdown-content bg-base-200 rounded-box w-52">
										<li onClick={closeDropdown}><Link to="/" className="text-neutral-content font-bold"><IoHome /> Home</Link></li>
										<li onClick={closeDropdown}><Link to="/myaccount" className='text-neutral-content font-bold'>< MdDataObject /> My Cirths</Link></li>
										<li onClick={closeDropdown}><Link to="/bridge" className="text-neutral-content font-bold">< TbBuildingBridge2 /> Bridge</Link></li>
										<li onClick={closeDropdown}>
											<a href={`${OPENSEA_URL + "account"} `} className='text-neutral-content font-bold'><SiOpensea /> My opensea</a></li>
										<li onClick={closeDropdown}>
											<div
												onClick={() => handleToggle()}
												className="text-neutral-content font-bold"
											>
												{theme === DARK_THEME ? <><IoSunnyOutline /> Light</> : <><IoMoonOutline /> Dark</>}
											</div>
										</li>
										<li className="h-[1px]"></li>
										<li onClick={closeDropdown}>
											<div
												className="text-red-500 font-bold"
												onClick={(() => {
													disconnect();
												})}>
												<MdLogout /> Logout
											</div>
										</li>
									</ul>
								</div>: 
								<div className="flex border-2 border-error rounded-lg px-3 py-1 text-sm bg-error text-white">
									Wrong network
								</div>}
							</label> :
							<label htmlFor="loginModal" className="flex-none text-secondary-content font-bold bg-secondary px-5 py-2 rounded-md cursor-pointer" >
								Connect
							</label>
						}
					</label>
				</div>

				<Login />

			</div>
		</>)
}

export default Header;