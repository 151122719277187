import { mainnet, polygon, polygonMumbai, goerli } from 'wagmi/chains'

export const CHAINS = [mainnet, goerli, polygon, polygonMumbai];
export const SUPPORT_CHAIN_ID = 1;
export const INFURA_API_KEY = "2NGKD05T8i68cawO3JWbVuYqXPz";
export const PROJECT_ID = "afc5645f77c08fc2a0a49697db686122"; // wallet connecter Id
export const WEB3AUTH_PROJECT_ID = process.env.REACT_APP_WEB3AUTH_PROJECT_ID || "BKav5oh8gjv_9_K-kEfmnFEbEH4c7iqNFKM9kPNhLUaK-UJM9TK_wYY1pky9nRAJoUTC5bxqOPmam6tjVDqXi18";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const PAGE_SIZE = 10;
export const THEGRAPH_URL = "https://api.studio.thegraph.com/query/61629/cirth/v0.4.5";
export const DECIMALS = 0;
export const MINT_GAS_FEE_NO_BUY_FERC = BigInt(75561);
export const MINT_GAS_FEE_BUY_FERC = BigInt(277617);
export const FORMAT_NUMBER_DECIMAL = 6;
export const DARK_THEME = "forest";
export const LIGHT_THEME = "lofi";
export const PROTOCOL = "ferc-721";
export const CONFIRMATIONS = 2;
export const OPENSEA_API_KEY = "b9f126f748b44153bc703748411a86c0";
export const OPENSEA_URL = "https://opensea.io/";
export const SHARE_TEXT = "I found an awesome stuff: #Cirth Inscription. #FERC20 #FERC721 ";

// The following params must be same as factory contract
export const MAX_TICK_SIZE = 9;
export const MIN_TICK_SIZE = 3;
export const MIN_FERC_HOLD = BigInt("10000000000000000000"); // 10 ferc
export const MINT_TIP = BigInt("5000000000000000000"); // 5 ferc
export const FREEZE_TIME = 600;

export const testTicks = ["__test__", "__test2__"]; // ######

const NETWORK_CONFIG = [
	{
		id: 1,
		network: "eth",
		tick: "ETH",
		tickName: "Ethereum",
		contractAddress: "0xBad3a7A10C4792A4466f6BCFF02D01b211f1f97F",
		wethAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		fercAddress: "0x2eCBa91da63C29EA80Fbe7b52632CA2d1F8e5Be0",
		bridgeContract: "0xEDd2F03F209172607b09fbcD86c4De0F3C130041",
		rpc: "https://eth-mainnet.g.alchemy.com/v2/RUG78UdjviviJyLRUEk-JS6hNDTHvK18",
		scanUrl: "https://etherscan.io",
		openseaUrl: "https://www.okx.com/web3/marketplace/nft",
		openseaApi: "https://api.opensea.io/api/v2/chain/ethereum/",
		bgColor: "#dddddd",
		foreColor: "#000",
	}, {
		id: 5,
		network: "goerli",
		tick: "ETH",
		symbol: "Ethereum",
		contractAddress: "0x00C8A24338226c939a41e5C2954Bf51B6E3935Cf",
		wethAddress: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
		fercAddress: "0x2ba13129106FcAc97DbdB565b767E144B3D5291B",
		bridgeContract: "0xbd3ea116f79d9bA46fE2fCF1A679B08754bE8d23",
		rpc: "https://goerli.infura.io/v3/2NGKD05T8i68cawO3JWbVuYqXPz",
		scanUrl: "https://goerli.etherscan.io",
		openseaUrl: "https://www.okx.com/web3/marketplace/nft",
		openseaApi: "https://testnets-api.opensea.io/api/v2/chain/goerli",
		bgColor: "#1100ff",
		foreColor: "#fff",
	}, {
		id: 137,
		network: "polygon",
		tick: "Matic",
		symbol: "Matic",
		contractAddress: "0xDb459B3d37ED0b0307F3eACE69376167d779Fd19",
		wethAddress: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
		fercAddress: "0xfA9f32c86AFe9416d001cbFf4bfAf89E31708c11",
		bridgeContract: "0xd744F54fB77CB75F8Ae6949018bdF7FC970E5568",
		rpc: "https://polygon-mainnet.g.alchemy.com/v2/Lptp-8NferN3uPNBaSB-jvDZeOa_cOus",
		scanUrl: "https://polygonscan.com",
		openseaUrl: "https://www.okx.com/web3/marketplace/nft",
		openseaApi: "https://testnets-api.opensea.io/api/v2/chain/polygon",
		bgColor: "#1100ff",
		foreColor: "#fff",
	}
];

export const getNetwork = (id: number) => NETWORK_CONFIG.find((v, i) => v.id === id);

/**
 * okx url
 * to nft token: https://www.okx.com/web3/marketplace/nft/asset/{network}/{contractAddress}/{tokenId}
 * to collection: https://www.okx.com/web3/marketplace/nft/collection/{network}/{contractAddress}
 */