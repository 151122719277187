import { FC, useEffect, useState } from "react";
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import useDevice from "../hooks/useDevice";
import { useAccount, useNetwork } from "wagmi";
import { queryMintByHolder, queryMintByHolderAndInscriptionAddress } from "../utils/graphQuery";
import { MintData, NetworkData } from "../utils/types";
import { parseQueryMintData } from "../utils/common";
import InscriptionCard from "./InscriptionCard";
import toast, { Toaster } from "react-hot-toast";
import { SUPPORT_CHAIN_ID, getNetwork } from "../config";
import { FiSearch } from "react-icons/fi";

interface MyAccountProps {
	graphClient: ApolloClient<NormalizedCacheObject>;
}

const MyAccount: FC<MyAccountProps> = ({
	graphClient
}) => {
	const pageSize = 40;
	const [list, setList] = useState([] as Array<MintData>);
	const isMobile = useDevice();
	const { chain } = useNetwork();
	const { address: currentAddress } = useAccount();
	const [currentPage, setCurrentPage] = useState(1);
	const [isEnd, setIsEnd] = useState(false);
	const [searchKey, setSearchKey] = useState("");
	const [network, setNetwork] = useState({} as NetworkData);

	useEffect(() => {
		if (chain) {
			const chainId = chain.id;
			if (chainId !== SUPPORT_CHAIN_ID) {
				toast.error("Only support chain id: " + SUPPORT_CHAIN_ID);
				return;
			}
			const _network = getNetwork(chainId);
			setNetwork({
				openseaUrl: _network?.openseaUrl as string,
				network: _network?.network as string,
			} as NetworkData)

			if (currentAddress !== undefined) getMintList(currentPage);
		}
	}, [currentAddress]);

	const getMintList = async (page: number) => {
		try {
			if (isEnd) {
				return
			}
			if (page < 1) {
				setCurrentPage(1)
				return
			}

			setCurrentPage(page)
			const result = await graphClient.query({
				query: gql`${queryMintByHolder}`,
				variables: {
					owner: currentAddress,
					first: pageSize,
					skip: pageSize * (page - 1)
				}
			});
			const mints = new Array<MintData>();
			if (result.error !== undefined || result.data.mints[0] === undefined) {
				toast.error("NO MORE");
				setCurrentPage(page - 1)
				setIsEnd(true);
				return
			}
			setList([]);
			for (let i = 0; i < result.data.mints.length; i++) {
				const data = result.data.mints[i];
				mints.push(parseQueryMintData(data));
			}

			if (mints.length < pageSize) {
				setIsEnd(true)
			}
			else setIsEnd(false);
			setList(mints);
		} catch (err: any) {
			console.log(err);
		}
	}

	const newSearch = () => {
		setIsEnd(false);
		setCurrentPage(1);

		if(searchKey === "") getMintList(1);
		else search(1);
	}

	// queryMintByHolderAndInscriptionAddress
	const search = async (page: number) => {
		try {
			if (isEnd) {
				return
			}
			if (page < 1) {
				setCurrentPage(1)
				return
			}

			setCurrentPage(page)
			const result = await graphClient.query({
				query: gql`${queryMintByHolderAndInscriptionAddress}`,
				variables: {
					owner: currentAddress,
					first: pageSize,
					tick: searchKey,
					// inscriptionAddress: searchKey,
					skip: pageSize * (page - 1)
				}
			});
			// console.log(result.data);
			const mints = new Array<MintData>();
			if (result.error !== undefined || result.data.mints[0] === undefined) {
				toast.error("NO MORE");
				setCurrentPage(page - 1)
				setIsEnd(true);
				return
			}
			setList([]);
			for (let i = 0; i < result.data.mints.length; i++) {
				const data = result.data.mints[i];
				mints.push(parseQueryMintData(data));
			}

			if (mints.length < pageSize) {
				setIsEnd(true)
			}
			else setIsEnd(false);
			setList(mints);
		} catch (err: any) {
			console.log(err);
		}
	}

	return (
		<>
			{currentAddress === undefined ? 
				<div className="mt-24 text-center w-full">{"Please connect wallet"}</div> :
				list.length > 0 &&
				<div className={`${isMobile ? "mt-24" : "mt-[110px]"} w-full`}>
					<div className="flex input-group justify-center text-center text-base-content mb-5">
						<input 
							type="text" 
							placeholder="Search by tick" 
							className="input bg-base-300 w-full max-w-xs	md:max-w-lg" 
							value={searchKey}
							onKeyUp={(e) => { if (e.code === "Enter") {
								newSearch();
							} }}
							onChange={(e) => setSearchKey(e.target.value)}
						/>
						<button
							className="btn btn-md text-base-content"
							onClick={() => newSearch()}
						>
							<FiSearch className='text-2xl' />
						</button>
					</div>
					<div className={`flex flex-wrap flex-row gap-2 w-full md:px-24 px-2 mb-20`}>
						{list.map((mintData, index) => <InscriptionCard key={index} mintData={mintData} openseaUrl={network.openseaUrl} network={network.network} />)}
					</div>
				</div>}

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 2000,
			}} />
			{/* pagination */}
			<div className=" w-full text-center">

				<div className="btn-group mt-5">
					<button className={`btn ${currentPage > pageSize ? 'btn-active' : 'disabled'}`} onClick={() => { setIsEnd(false); getMintList(currentPage - 1) }}>{"<<"}</button>
					<button className={`btn ${!isEnd ? 'btn-active' : ''}`} onClick={() => { getMintList(currentPage + 1) }}>{">>"}</button>
				</div>
			</div>

		</>
	)
}

export default MyAccount;
