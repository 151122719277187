import { FC } from "react";
import { MintData } from "../utils/types"
import { formatAddress, formatDate, toNumber } from "../utils/common";
import LinkTo from "./LinkTo";
import { PROTOCOL } from "../config";

interface InscriptionCardProps {
	mintData: MintData,
	openseaUrl: string,
	network: string,
}
const InscriptionCard:FC<InscriptionCardProps> = ({
	mintData,
	openseaUrl,
	network
}) => {

	return (
		<div className="card min-w-[150px] max-w-[200px] w-[48%] md:min-w-[175px] md:w-[16%] bg-base-200 hover:bg-base-300 text-base-content text-sm">
			<div className="card-body p-3">
				<div className="flex text-xs justify-end">
					{formatAddress(mintData.inscriptionAddress)}
					<LinkTo copyText={mintData.inscriptionAddress}/>
				</div>
				<div className="font-bold text-xs">
					<div>{"{"}</div>
					<div className="ml-4">{`"p": ${PROTOCOL}`}</div>
					<div className="ml-4">{`"op": "mint"`}</div>
					<div className="ml-4">{`"tick": "${mintData.tick}"`}</div>
					<div className="ml-4">{`"max": ${mintData.max}`}</div>
					<div className="ml-4">{`"limit": ${mintData.limit}`}</div>
					<div className="ml-4">{`"id": ${mintData.tokenId}`}</div>
					<div>{"}"}</div>
				</div>
				{/* <div className="divider h-2"/> */}
				<div className="flex justify-between mt-2 pt-2 text-xs">
					{/* <div>#{`${mintData.globalId}`}</div> */}
					<div className="text-xs text-base-content">{`${formatDate(toNumber(mintData.blockTimestamp) * 1000, "YY/MM/DD HH:mm")}`}</div>
				</div>
				<div className="flex justify-between mt-1 text-xs">
					{/* <div className="flex"> */}
						<a href={`${openseaUrl}/asset/${network}/${mintData.inscriptionAddress}/${mintData.tokenId}`}>
							{/* {`${formatAddress(mintData.inscriptionAddress)}`} */}
							<div className="btn btn-outline btn-accent btn-xs px-3">List</div>
						</a>
						
						<a href={`/bridge/${mintData.tick}/${mintData.tokenId}`}>
							{/* {`${formatAddress(mintData.inscriptionAddress)}`} */}
							<div className="btn btn-outline btn-secondary btn-xs px-3">Bridge</div>
						</a>
					{/* </div> */}
				</div>
			</div>
		</div>
	)
}

export default InscriptionCard;