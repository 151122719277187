import useDevice from '../hooks/useDevice';
import Logo from './Logo';
import { BsTwitterX } from "react-icons/bs";
import { FaBook, FaTelegramPlane } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import { FiGithub } from "react-icons/fi";
import { BsFiletypeDoc } from "react-icons/bs";


const Footer = () => {
	return(
		<footer className="fixed bottom-0 footer h-0 px-5 md:h-20 md:px-10 md:py-5 items-center bg-base-200 text-base-content">
			<div className="items-center grid-flow-col">
				<Logo />
			</div> 
			<div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
				<div className='text-2xl'><a href="https://docs.cirth.meme/" target="_blank" rel="noreferrer"><FaBook /></a></div>
				<div className='text-2xl'><a href="https://mirror.xyz/0x7fB68Da285A8ea46137086bEf02422ae2BBA496a" target="_blank" rel="noreferrer"><BsFiletypeDoc /></a></div>
				<div className="text-2xl"><a href="https://twitter.com/cirth_runes" target="_blank" rel="noreferrer"><BsTwitterX /></a></div>
				<div className='text-2xl'><a href="https://github.com/cirthdev" target="_blank" rel="noreferrer"><FiGithub /></a></div>
				<div className='text-2xl'><a href='https://t.me/cirth_meme/1' target='_blank' rel="noreferrer"><FaTelegramPlane /></a></div>
				<p className="mt-1">Copyright © 2023 - All right reserved</p>
			</div>
		</footer>
	)
} 

export default Footer;