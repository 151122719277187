import { FC } from "react";
import { TransactionsProps } from "../utils/types";
import { formatAddress, formatDate, toNumber } from "../utils/common";
import LinkTo from "./LinkTo";
import useDevice from "../hooks/useDevice";
import { DARK_THEME } from "../config";
import { useStore } from "../hooks/useStore";

const Transactions: FC<TransactionsProps> = ({
	transactionsList
}) => {
	const isMobile = useDevice();
  const theme = useStore((state) => state.theme);

	return(
		<div className={`mt-2`}>
		<div className="mb-3 font-bold ml-3">Transactions</div>
		<table className={`table w-full ${isMobile ? "text-sm" : "text-md"}`}>
			<thead>
				<tr>
					{/* <td className="w-[10%]">#</th> */}
					<td className="w-[40%]">hash</td>
					<td className="text-center w-[20%]">token id</td>
					<td className="text-right w-[30%]">time</td>
				</tr>
			</thead>
			<tbody>
			{transactionsList.map((value, index) => 
				<tr key={index} className={`${theme === DARK_THEME ? "table-tr-dark" : "table-tr"}`}>
					{/* <td className="text-left">{index + 1}</td> */}
					<td className="text-left">
						<div className="flex">
							{formatAddress(value.transactionHash)}
							<LinkTo copyText={value.transactionHash} />
						</div>
					</td>
					<td className="text-center">{value.tokenId.toString()}</td>
					<td className="text-right">{formatDate(toNumber(value.blockTimestamp) * 1000, "MM/DD HH:mm")}</td>
				</tr>
			)}
			</tbody>
		</table>
	</div>
	)
}

export default Transactions;
