import { ethers } from "ethers";
import { Token } from '@uniswap/sdk-core';
import { computePoolAddress, FeeAmount } from '@uniswap/v3-sdk';
import { getNetwork } from "../config";
import uniswapV3PoolJson from '../abi/UniswapV3Pool.json';
import quoterJson from '../abi/Quoter.json';

const uniswapV3FactoryAddress = "0x1f98431c8ad98523631ae4a59f267346ea31f984";
const quoterAddress = "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6";

const getEthFercPoolAddress = (chainId: number) => {
	const network = getNetwork(chainId);
	const WETH_TOKEN = new Token(chainId, network?.wethAddress as string, 18, 'WETH', 'Wrapped Ether');
	const FERC_TOKEN = new Token(chainId, network?.fercAddress as string, 18, 'FERC', 'FairERC20');
	const poolFee = FeeAmount.HIGH;
	return computePoolAddress({
		factoryAddress: uniswapV3FactoryAddress,
		tokenA: WETH_TOKEN,
		tokenB: FERC_TOKEN,
		fee: poolFee,
	});
}

export const getEthFercExactOutputSingle = async (chainId: number, outputAmount: BigInt): Promise<any> => {
	try {
		const poolAddress = getEthFercPoolAddress(chainId);
		const network = getNetwork(chainId);
		const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
		const poolContract = new ethers.Contract(poolAddress, uniswapV3PoolJson, provider);
		const quoterContract = new ethers.Contract(quoterAddress, quoterJson, provider);
	
		const [token0, token1, fee, liquidity, slot0] = await Promise.all([
			poolContract.token0(),
			poolContract.token1(),
			poolContract.fee(),
			poolContract.liquidity(),
			poolContract.slot0(),
		])

		const inputAmount = await quoterContract.callStatic.quoteExactOutputSingle(
			token1,
			token0,
			fee,
			outputAmount,
			0
		);
		return inputAmount;
	} catch (err: any) {
		console.log(err.message);
		return false;
	}
}

const getEthUsdPrice = async () => {
	// TODO: get price by cryptocompare api

}

export const getGasPrice = async (chainId: number): Promise<any> => {
	const network = getNetwork(1); // get gas for base mainnet
	const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
	return await provider.getGasPrice();
}