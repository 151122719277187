// Web3Auth Libraries
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter, OPENLOGIN_NETWORK } from "@web3auth/openlogin-adapter";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { TorusWalletConnectorPlugin } from "@web3auth/torus-wallet-connector-plugin";
import { Chain } from "wagmi";
import { WEB3AUTH_PROJECT_ID } from "../config";
import { Web3AuthNoModal } from "@web3auth/no-modal";

export default function Web3AuthNoModalInstance(chains: Chain[], loginProvider: string) {
  // Create Web3Auth Instance
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x" + chains[0].id.toString(16),
    rpcTarget: chains[0].rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
    displayName: chains[0].name,
    tickerName: chains[0].nativeCurrency?.name,
    ticker: chains[0].nativeCurrency?.symbol,
    blockExplorer: chains[0].blockExplorers?.default.url[0] as string,
  };

  const web3AuthInstance = new Web3AuthNoModal({
    clientId: WEB3AUTH_PROJECT_ID,
    chainConfig,
    // clientId: process.env.REACT_APP_WEB3AUTH_PROJECT_ID as string,
    web3AuthNetwork: OPENLOGIN_NETWORK.SAPPHIRE_MAINNET,
    enableLogging: true,
  });

  // // Add openlogin adapter for customisations
  const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
  const openloginAdapterInstance = new OpenloginAdapter({
    privateKeyProvider,
    // adapterSettings: {
    // uxMode: "popup",
    // whiteLabel: {
    // 	appName: "W3A",
    // 	appUrl: "https://app.cirth.meme",
    // 	logoLight: "https://web3auth.io/images/web3auth-logo.svg",
    // 	logoDark: "https://web3auth.io/images/web3auth-logo---Dark.svg",
    // 	defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
    // 	mode: "dark", // whether to enable dark mode. defaultValue: auto
    // 	theme: {
    // 		primary: "#00D1B2",
    // 	},
    // 	useLogoLoader: true,
    // },
    // loginConfig: {
    // 	// Add login configs corresponding to the provider
    // 	// Add other login providers here
    // },
    // },
  });
  web3AuthInstance.configureAdapter(openloginAdapterInstance);

  // Add Torus Wallet Plugin (optional)
  const torusPlugin = new TorusWalletConnectorPlugin({
    torusWalletOpts: {
      buttonPosition: "bottom-left",
    },
    walletInitOptions: {
      whiteLabel: {
        theme: {
          isDark: true,
          colors: {
            primary: "#00a8ff",
          },
        },
        logoDark: "https://images.web3auth.io/web3auth-logo-w.svg",
        logoLight: "https://images.web3auth.io/web3auth-logo-w-light.svg",
      },
      useWalletConnect: true,
      enableLogging: true,
    },
  });
  web3AuthInstance.addPlugin(torusPlugin);

  return new Web3AuthConnector({
    chains: chains as any,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider
      }
    },
  });
}
