import { FC, useState } from "react";
import toast from "react-hot-toast";
import { DECIMALS, MAX_TICK_SIZE, MIN_FERC_HOLD, MIN_TICK_SIZE, ZERO_ADDRESS } from "../config";
import SmartInscriptionFactoryABI from '../abi/SmartInscriptionFactory.json';
import { readContract, writeContract, waitForTransaction } from "@wagmi/core";
import { ethers } from "ethers";
import { DeployProps } from "../utils/types";
import Tooltip from "./Tooltip";
import intl from "../utils/intl";
import { getErrorMessage, toNumber } from "../utils/common";

const Deploy: FC<DeployProps> = ({
	network,
}) => {
	const [deployTick, setDeployTick] = useState("");
	const [deployCap, setDeployCap] = useState("");
	const [deploying, setDeploying] = useState(false);
	const [limit, setLimit] = useState("");
	const [needFerc, setNeedFerc] = useState(false);

	const deploy = async () => {
		// toast.error("We are almost done, please be patient to wait a few hours...");
		// return;
		
		if(deployTick.trim().length < MIN_TICK_SIZE || deployTick.trim().length > MAX_TICK_SIZE) {
			toast.error("Tick size should be " + MIN_TICK_SIZE + " to " + MAX_TICK_SIZE);
			return;
		}

		if(BigInt(deployCap) > BigInt("18446744073709551615") || BigInt(limit) > BigInt("16777215")) {
			toast.error("Max should be less than 18,446,744,073,709,551,615, and limit should be less than 16,777,215");
			return;
		}
		
		if(deployTick.trim() === "" || deployTick.match(/[^\x00-\xff]/gm) !== null) {
			toast.error("Please input right tick");
			return;
		};

		try {
			setDeploying(true);
			const contractAddress = network.contractAddress;
			const tickExist: any = await readContract({
				address: contractAddress as `0x{string}`,
				abi: SmartInscriptionFactoryABI,
				functionName: 'tickExists',
				args: [deployTick],
			});

			// Check if the tick is deployed
			if(!tickExist) {
				// Deploying
				const { hash } = await writeContract({
					address: contractAddress as `0x{string}`,
					abi: SmartInscriptionFactoryABI,
					functionName: 'deploy',
					args: [
						deployTick, 
						ethers.utils.parseUnits(deployCap.toString(), DECIMALS), // cap
						ethers.utils.parseUnits(limit.toString(), DECIMALS), // limit
						needFerc,
					],
				});
		
				waitForTransaction({hash}).then(async (data) => {
					if(data.status === 'success') {
						toast.success("Deploy successfully");
					} else {
						toast.error("Deploy Error");
					}
					setDeploying(false);
				})

			} else {
				setDeploying(false);
				toast.error("Tick has been minted");
			}
		} catch(err: any) {
			console.log(err)
			toast.error(getErrorMessage(err.message));
			setDeploying(false);
		}
	}
	
	const closeModal = () => (document.getElementById("deployModal") as HTMLInputElement).checked = false;

	return(
	<>
		<div>
			<input type="checkbox" id="deployModal" className="modal-toggle" />
			<label htmlFor="deployModal" className="modal cursor-pointer">
				<label className="modal-box" htmlFor="">
					<label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={() => closeModal()}>✕</label>
					<h3 className="font-bold text-lg">{intl.get("deploy")}</h3>
					<div className="divider"/>

					<label className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left text-sm md:text-md">{intl.get("tick")}
						<Tooltip 
							message={intl.get("tooltip-tick")}
						/>
						</span>
						<input type="text" value={deployTick} placeholder={`${MIN_TICK_SIZE} to ${MAX_TICK_SIZE} characters like 'abcd'...`} className="input input-bordered w-[60%]" onChange={(e) => setDeployTick(e.target.value)}/>
					</label>

					<label className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left text-sm md:text-md">{intl.get("hard-cap")}</span>
						<input type="text" value={deployCap} placeholder="21000000000000" className="input input-bordered w-[60%]" onChange={(e) => setDeployCap((parseInt(e.target.value === '' ? '0' : e.target.value).toString()))} />
					</label>

					<label className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left text-sm md:text-md">{intl.get("limit")}</span>
						<input type="text" value={limit} placeholder="1000000" className="input input-bordered w-[60%]" onChange={(e) => setLimit((parseInt(e.target.value === '' ? '0' : e.target.value).toString()))} />
					</label>

					<label className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left text-sm md:text-md">{`FERC Family`}
						<Tooltip 
							message={`Must hold at least ${ethers.utils.formatEther(MIN_FERC_HOLD)} Ferc to mint.`}
						/>
						</span>
						<div className="input w-[60%] flex">
							<span className="bg-base-100">No</span> 
							<input type="checkbox" className="toggle mt-3" checked={needFerc} onClick={() => setNeedFerc(!needFerc)} onChange={() => {}}/>
							<span className="bg-base-100">Yes</span> 
						</div>
					</label>
					
					<div className="modal-action">
						<button className={`btn btn-outline ${deploying ? 'loading btn-disabled' : 'btn-primary'}`} onClick={() => deploy()}>{intl.get("deploy")}</button>
					</div>
				</label>
			</label>
		</div>
	</>)
}

export default Deploy;