import { FC, useState } from "react";
import { TokenCardListProps } from "../utils/types";
import toast from "react-hot-toast";
import { formatAddress, formatDate, toNumber } from "../utils/common";
import LinkTo from "./LinkTo";
import { useAccount } from "wagmi";
import { BsBookmarkCheckFill } from "react-icons/bs";
import FercFamilyLogo from "./FercFamilyLogo";
import { mintPercent } from "./TokenList";

const TokenList: FC<TokenCardListProps> = ({
	list,
	showType,
	scanUrl,
	symbol,
	// version,
	factory,
	currentAddress,
	setMintItem,
	checkTick,
}) => {
	const { address } = useAccount();

	return (
		<div className="mb-20">
			{list && list.map((item, key) => 
				<div key={key} className="w-full my-3 rounded-2xl px-4 py-4 bg-base-100">
					<div className="flex justify-between w-full">
						<div className="text-neutral-content text-md w-[30%] text-left text-md font-bold">{item.tick}</div>
						{item.needFerc && <FercFamilyLogo/>}
						{/* <div className="badge bg-base-200 w-[25%] mt-1 badge-xs text-left">ferc-721</div> */}
						<div className="flex w-[70%] justify-end text-xs mt-1 md:text-md">{formatAddress(item.inscriptionAddress, 10)}
						<LinkTo copyText={item.inscriptionAddress}/>
						</div>
					</div>

					<div className="flex justify-between w-full mt-1">
						<div className="text-md w-[33%] text-left text-xs">Cap:{item.max.toString()}</div>
						<div className="text-md w-[33%] text-center text-xs">Limit:{item.limit?.toString()}</div>
						<div className="text-md w-[34%] text-right text-xs">Mint:{item.totalSupply?.toString()}</div>
					</div>

					<div className="flex justify-between w-full mt-1">
						<div className="text-md w-[50%] text-left text-xs">Holders:{item.holders?.toString()}</div>
						<div className="text-md w-[50%] text-right text-xs">Since:{formatDate(toNumber(item.blockTimestamp) * 1000, "MM/DD mm:ss")}</div>
					</div>

					<div className="flex justify-between w-full mt-1">
						<progress className="progress progress-secondary w-[70%] mt-2" value={mintPercent(item).toString()} max="100"></progress>
						<div>
							<label
								htmlFor="mintModal"
								className="btn btn-outline btn-secondary btn-xs px-5"
								onClick={async () => {
									if(address === undefined) {
										toast.error("Please connect wallet!");
									} else {
										setMintItem(item);
										checkTick(item.tick, 1)
									}
								}}
							>
								{mintPercent(item) < 100 ? "Mint" : "Enter"}
							</label>
						</div>
					</div>
				</div>
			)}
		</div>)
}

export default TokenList;