// 		deploys(first: $first,skip: $skip, orderBy: holders, orderDirection: desc) {

export const queryDeployList = `
	query Deploys($first: Int,$skip: Int) {
		deploys(first: $first,skip: $skip, orderBy: holders, orderDirection: desc) {
			id
			transactionHash
			inscriptionId
			tick
			max
			limit
			needFerc
			inscriptionAddress
			totalSupply
			completed
			holders
			transactions
			blockTimestamp
			blockNumber
		}
	}
	`
export const queryDeployListByType = `
query Deploys($first: Int,$skip: Int, $completed: Boolean) {
	deploys(first: $first,skip: $skip, orderBy: holders, orderDirection: desc, where: {completed: $completed}) {
		id
		transactionHash
		inscriptionId
		tick
		max
		limit
		needFerc
		inscriptionAddress
		totalSupply
		completed
		holders
		transactions
		blockTimestamp
		blockNumber
	}
}
`

export const querySearchDeployList =  `
query SearchDeploys($first: Int,$skip: Int, $key: String) {
	deploys(first: $first,skip: $skip, orderBy: holders, orderDirection: desc, where: {tick_contains_nocase: $key}) {
		id
		transactionHash
		inscriptionId
		tick
		max
		limit
		needFerc
		inscriptionAddress
		totalSupply
		completed
		holders
		transactions
		blockTimestamp
		blockNumber
	}
}
`

export const queryDeployDetailsByAddress = `
	query getDeploy($inscriptionAddress: String) {
		deploy(id: $inscriptionAddress) {
			id
			inscriptionId
			tick
			max
			limit
			needFerc
			inscriptionAddress
			transactionHash
			totalSupply
			completed
			holders
			transactions
			blockTimestamp
			blockNumber
		}
	}`;

export const queryDeployDetailsByTick = `
	query getDeploy($tick: String) {
		deploys(where: {tick: $tick}) {
			id
			inscriptionId
			tick
			max
			limit
			needFerc
			inscriptionAddress
			transactionHash
			totalSupply
			completed
			holders
			transactions
			blockTimestamp
			blockNumber
		}
	}`;

export const queryMint = `
	query getMint($inscriptionAddress: String, $tokenId: Int) {
		mints(
			where: {inscriptionAddress: $inscriptionAddress, tokenId: $tokenId}
		) {
			id
			inscriptionId
			inscriptionAddress
			blockTimestamp
			max
			limit
			tick
			tokenId
			totalSupply
			owner
			transactionHash
		}
	}
`;

export const queryMintByHolder = `
	query getMintByHolder($owner: String, $first: Int, $skip: Int) {
		mints(
			where: {owner: $owner}
			orderBy: blockTimestamp
			orderDirection: desc
			first: $first
			skip: $skip
		) {
			id
			inscriptionId
			inscriptionAddress
			blockTimestamp
			max
			limit
			tick
			tokenId
			totalSupply
			owner
			transactionHash
		}
	}
`;

/**
  				}, {
					inscriptionAddress_contains: $inscriptionAddress
 */
export const queryMintByHolderAndInscriptionAddress = `
	query getMintByAddress($owner: String, $tick: String, $inscriptionAddress: Bytes, $first: Int, $skip: Int) {
		mints(
			where: {
				and : [{
					owner: $owner
				}, {
				or: [{
					tick_contains_nocase: $tick
				}]
			}]}
			orderBy: blockTimestamp
			orderDirection: desc
			first: $first
			skip: $skip
		) {
			id
			inscriptionId
			inscriptionAddress
			blockTimestamp
			max
			limit
			tick
			tokenId
			totalSupply
			owner
			transactionHash
		}
	}
`;

export const queryMintByHolderAndInscriptionAddressAll = `
	query getMintByAddress($owner: String, $tick: String) {
		mints(
			where: {
				and : [{
					owner: $owner
				}, {
				or: [{
					tick_contains_nocase: $tick
				}]
			}]}
			orderBy: tokenId
			orderDirection: asc
		) {
			id
			inscriptionId
			inscriptionAddress
			blockTimestamp
			max
			limit
			tick
			tokenId
			totalSupply
			owner
			transactionHash
		}
	}
`;

export const queryTotalInscriptions = `
	query {
		deploys(first: 1, orderDirection: desc, orderBy: inscriptionId) {
			inscriptionId
		}
	}
`

export const queryHolders = `
	query Holders($first: Int,$skip: Int,$inscriptionAddress: String){
		holders(
			where: {inscriptionAddress: $inscriptionAddress}
			orderBy: amount
			orderDirection: desc
			first: $first
			skip: $skip
		) {
			id
			inscriptionAddress
			address
			amount
		}
	}
`

export const queryTransactions = `
	query Transactions($first: Int,$skip: Int,$inscriptionAddress: String) {
		transfers(
			where: {inscriptionAddress: $inscriptionAddress}
			orderBy: blockTimestamp
			orderDirection: desc
			first: $first
			skip: $skip
		) {
			transactionHash
			blockNumber
			blockTimestamp
			inscriptionAddress
			tokenId
			from
			to
		}
	}
`