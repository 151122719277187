import appinfo from "../../package.json";

const Logo = () => {
	return (
		<div className="flex tracking-wide cursor-pointer">
			<div className="w-9 md:w-10 mr-2">
				<img src="/images/logo.png" alt=""></img>
			</div>
			<div>
				<div className="font-bold text-xl md:text-3xl">cirth</div>
				<div className="text-xs md:text-md -mt-2 text-right">.meme</div>
			</div>
			{/* <div className="font-extrabold text-xl md:text-3xl">{".io"}</div> */}
			{/* <div className="text-md ml-3">Ferc721</div> */}
			{/* <div className="text-md ml-3">{appinfo.version}</div> */}
		</div>
	)
}

export default Logo;