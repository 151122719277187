import intl from 'react-intl-universal';
import en_US from "../locales/en_US.json";
import zh_CN from "../locales/zh_CN.json";

const locales: any = {
  en: en_US,
  // zh: zh_CN,
};
// const lang = navigator.userAgent.indexOf("lang=[zh]") > -1 ? "zh" : navigator.userAgent.indexOf("lang=[en]") > -1 ? "en" :
//   (navigator.languages && navigator.languages[0]) || navigator.language;

const lang="en"

intl.init({
  currentLocale: lang.split("-")[0],
  locales: locales,
});

export default intl;
