import { FC, useState } from "react";
import { HolderListProps } from "../utils/types";
import { formatAddress, toNumber } from "../utils/common";
import LinkTo from "./LinkTo";
import useDevice from "../hooks/useDevice";
import { DARK_THEME } from "../config";
import { useStore } from "../hooks/useStore";

const HolderList: FC<HolderListProps> = ({
	hodlersList,
	totalSupply,
}) => {
	const isMobile = useDevice();
  const theme = useStore((state) => state.theme);

	return(
	<div className={`mt-2`}>
		<div className="mb-3 font-bold ml-3">Top Holders</div>
		<table className={`table w-full ${isMobile ? "text-sm" : "text-md"}`}>
			<thead>
				<tr>
					<td className="w-[40%]">address</td>
					<td className="text-center w-[20%]">amount</td>
					<td className="text-right w-[30%]">ratio</td>
				</tr>
			</thead>
			<tbody>
			{totalSupply !== null && hodlersList.map((value, index) => 
				<tr key={index} className={`${theme === DARK_THEME ? "table-tr-dark" : "table-tr"}`}>
					<td className="text-left">
						<div className="flex">
						{formatAddress(value.ownerAddress)}
						<LinkTo copyText={value.ownerAddress}/>
						</div>
					</td>
					<td className="text-center">{value.amount.toString()}</td>
					<td className="text-right">{(toNumber(value.amount) * 100 / toNumber(totalSupply)).toFixed(2) + "%"}</td>
				</tr>
			)}
			</tbody>
		</table>

	</div>)
}

export default HolderList;