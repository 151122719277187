import { FC } from "react";
import { DARK_THEME, LIGHT_THEME } from "../config";
import { useConnect } from "wagmi";
import { FaGoogle } from "react-icons/fa";
import { BsTwitterX, BsWechat, BsWallet2 } from "react-icons/bs";
import { SiWalletconnect } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import { IWeb3Auth, WALLET_ADAPTERS } from "@web3auth/base";
import toast from "react-hot-toast";

interface LoginProps {
	// isOpen: boolean;
}

const Login:FC<LoginProps> = ({
	// isOpen
}) => {
	const theme = localStorage.getItem("theme") ? localStorage.getItem("theme") : DARK_THEME
  const { connect, connectors, error } = useConnect();

	const setOpen = (bl: boolean) => (document.getElementById("loginModal") as HTMLInputElement).checked = bl;

	const connectWallet = async (connectorId: number) => {
		connect({ connector: connectors[connectorId] });
		setOpen(false);	
		// if(connectorId < 3) {
		// 	connect({ connector: connectors[connectorId] });
		// 	setOpen(false);	
		// } else {
		// 	// web3auth no modal login
		// 	// 3- twitter, 4- google, 5- webchat
		// 	const loginProvider = connectorId === 3 ? "twitter" : connectorId === 4 ? "google" : "wechat";
		// 	const web3auth = connectors[3].options.web3AuthInstance as IWeb3Auth;
		// 	await web3auth.init();
		// 	if(web3auth.connected) {
		// 		// await web3auth.logout();
		// 		toast.success("You have loggined");
		// 		setOpen(false);
		// 	} else {
		// 		const web3authProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
		// 			loginProvider,
		// 		})
	
		// 		setOpen(false);	
		// 	}
		// }
	}

	return(
		<div>
			<input type="checkbox" id="loginModal" className="modal-toggle" />
			<label htmlFor="accountModal" className="modal cursor-pointer">
				<label className="modal-box" htmlFor="">
					<label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={() => setOpen(false)}>✕</label>
					<h3 className="font-bold text-lg">{"Connect Wallet"}</h3>
					<div className="w-[80%] ml-[10%] mt-10">
						<div>
							<div className="flex justify-center w-full h-11 py-4 md:h-14 md:py-5 bg-[#2c323d] font-bold text-md md:text-lg text-center text-primary-content mb-3 cursor-pointer rounded-xl" onClick={() => connectWallet(0)}><BsWallet2/><div className="-mt-1 ml-3">Injected Wallet</div></div>
							<div className="flex justify-center w-full h-11 py-4 md:h-14 md:py-5 bg-[#a95403cf] font-bold text-md md:text-lg text-center text-primary-content mb-3 cursor-pointer rounded-xl" onClick={() => connectWallet(1)}><img src="/images/metamask.svg"/><div className="-mt-1 ml-3">Metamask</div></div>
							<div className="flex justify-center w-full h-11 py-4 md:h-14 md:py-5 bg-[#3b98fc8e] font-bold text-md md:text-lg text-center text-primary-content mb-3 cursor-pointer rounded-xl" onClick={() => connectWallet(2)}><SiWalletconnect/><div className="-mt-1 ml-3">WalletConnect</div></div>
							<div className="flex justify-center w-full h-10 py-1 md:h-10 md:py-1">Login by Social account</div>
							<div className="flex justify-center w-full h-11 py-4 md:h-14 md:py-5 bg-[#1e1e1e] font-bold text-md md:text-lg text-center text-primary-content mb-3 cursor-pointer rounded-xl" onClick={() => connectWallet(3)}><BsTwitterX/><div className="-mt-1 ml-3">Twitter</div></div>
							<div className="flex justify-center w-full h-11 py-4 md:h-14 md:py-5 bg-[#e4453398] font-bold text-md md:text-lg text-center text-primary-content mb-3 cursor-pointer rounded-xl" onClick={() => connectWallet(4)}><FaGoogle/><div className="-mt-1 ml-3">Google</div></div>
							<div className="flex justify-center w-full h-11 py-4 md:h-14 md:py-5 bg-[#515151] font-bold text-md md:text-lg text-center text-primary-content mb-3 cursor-pointer rounded-xl" onClick={() => connectWallet(5)}><FaApple/><div className="-mt-1 ml-3">Apple ID</div></div>
							<div className="flex justify-center w-full h-11 py-4 md:h-14 md:py-5 bg-[#256e41] font-bold text-md md:text-lg text-center text-primary-content mb-3 cursor-pointer rounded-xl" onClick={() => connectWallet(6)}><BsWechat/><div className="-mt-1 ml-3">WeChat</div></div>
						</div>
					</div>
				</label>
			</label>
		</div>
	)
}

export default Login;