import dayjs from "dayjs";
import { BigNumber, ethers, providers, utils } from "ethers";
import { HttpTransport } from "viem";
import { type PublicClient, getPublicClient } from '@wagmi/core'
import { DeployData, HoldersData, MintData, TransactionsData } from "./types";
import { getNetwork } from "../config";

export const BN = (num: any): BigNumber => BigNumber.from(num);
export const formatEther = (wei: ethers.BigNumberish): string => ethers.utils.formatEther(wei);
export const parseEther = (eth: string): BigNumber => ethers.utils.parseEther(eth);

export const formatAddress = (address: string, len: number = 6): string => {
  if (address === null) return "";
  return address.substr(0, len) + "..." + address.substr(address.length - (len - 2), len - 2);
};

export const formatDate = (timestamp: number | undefined, format: string = "YYYY/MM/DD HH:mm"): string => {
	if (timestamp === undefined) return "";
	const date = new Date(timestamp);
	const formatter = dayjs(date);
	return formatter.format(format);
};

export const addCommas = (num: string) => {
	var str = num.split('.');
	if (str[0].length >= 4) {
		str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
	}
	if (parseInt(str[1]) === 0) return str[0];
	else {
		if (str[1] && str[1].length >= 4) {
			str[1] = str[1].replace(/(\d{3})/g, '$1 ');
		}
		return str.join('.');
	}
}

export const secondsToHMS = (seconds: number) => {
	if (seconds === 0) return "Free";
	let hours = Math.floor(seconds / 3600);
	let minutes = Math.floor((seconds - (hours * 3600)) / 60);
	let remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

	return (hours === 0 ? "0:" : hours + ":")
		+ (minutes === 0 ? "0:" : minutes + ":")
		+ (remainingSeconds === 0 ? "0" : Math.floor(remainingSeconds));
}

export const toNumber = (value: any) => BigNumber.from(value).toNumber();
export const toString = (value: any) => BigNumber.from(value).toString();
// export const toNumber = (value: any) => BN(value).toNumber();
// export const toString = (value: any) => BN(value).toString();

export const countZero = (number: number, len: number) => {
	const str = number.toFixed(30).toString();
	const decimalIndex = str.indexOf('.');
	const decimalPart = decimalIndex === -1 ? '' : str.slice(decimalIndex + 1);
	let zeroCount = 0;
	for (let i = 0; i < decimalPart.length; i++) {
		if (decimalPart[i] !== '0') {
			break;
		} else {
			zeroCount++;
		}
	}
	if (zeroCount === 30) return -len;
	else return zeroCount;
}

export const formatDecimal = (number: number, len: number) => {
	return number.toFixed(countZero(number, len) + len);
}

export const formatDecimal2 = (number: number, len: number) => {
	const str = number.toFixed(30).toString();
	const decimalIndex = str.indexOf('.');
	const intPart = decimalIndex === -1 ? str : str.substring(0, decimalIndex);
	const decimalPart = decimalIndex === -1 ? '' : str.slice(decimalIndex + 1);
	const zeroCount = countZero(number, len);
	// console.log(number, zeroCount, len - 1); // 1, 5
	if (zeroCount > len - 1) return intPart.replace(/(\d)(?=(\d{3})+$)/g, '$1,') + ".0{" + zeroCount + "}" + decimalPart.substring(zeroCount, zeroCount + len);
	else return addCommas(number.toFixed(zeroCount + len > 0 ? zeroCount + len - 1 : zeroCount + len));
}

const oneBN: BigNumber = utils.parseUnits("1", 18);
export const multiply = (bn: BigNumber | string, number: number): BigNumber => {
	const bnForSure = BN(bn);
	const numberBN = utils.parseUnits(number.toFixed(18).toString(), 18);
	return bnForSure.mul(numberBN).div(oneBN);
}

export const divide = (bn: BigNumber | string, number: number): BigNumber => {
	const bnForSure = BN(bn);
	const numberBN = utils.parseUnits(number.toFixed(18).toString(), 18);
	return bnForSure.div(numberBN).div(oneBN);
}

export const compareAddress = (address0: string, address1: string): boolean => {
	// return true if address0 smaller than address1
	const address0Hex = utils.hexlify(address0);
	const address1Hex = utils.hexlify(address1);
	const result = (address0Hex < address1Hex);
	return result;
}

export function publicClientToProvider(publicClient: PublicClient) {
	const { chain, transport } = publicClient
	const network = {
		chainId: chain.id,
		name: chain.name,
		ensAddress: chain.contracts?.ensRegistry?.address,
	}

	if (transport.type === 'fallback') {
		return new providers.FallbackProvider(
			(transport.transports as ReturnType<HttpTransport>[]).map(
				({ value }) => new providers.JsonRpcProvider(value?.url, network),
			),
		)
	}
	return new providers.JsonRpcProvider(transport.url, network)
}

export function getEthersProvider({ chainId }: { chainId?: number } = {}) {
	const publicClient = getPublicClient({ chainId })
	return publicClientToProvider(publicClient)
}

export const getWeb3Provider = (): ethers.providers.JsonRpcProvider => {
	return new ethers.providers.Web3Provider(window.ethereum);
}

export const parseQueryDeployData = (data: any): DeployData => {
	return {
		id: data.id,
		inscriptionId: data.inscriptionId === undefined ? 0 : BigInt(data.inscriptionId),
		tick: data.tick,
		max: data.max === undefined ? 0 : BigInt(data.max),
		limit: data.limit === undefined ? 0 : BigInt(data.limit),
		needFerc: data.needFerc,
		inscriptionAddress: data.inscriptionAddress,
		// globalId: data.globalId,
		blockTimestamp: data.blockTimestamp === undefined ? 0 : BigInt(data.blockTimestamp),
		blockNumber: data.blockNumber === undefined ? 0 : BigInt(data.blockNumber),
		name: data.__typename,
		totalSupply: data.totalSupply === undefined ? 0 : BigInt(data.totalSupply),
		completed: data.completed,
		holders: data.holders === undefined ? 0 : data.holders,
		transactions: data.transactions === undefined ? 0 : data.transactions,
	} as DeployData;
}

export const parseQueryMintData = (data: any): MintData => {
	return {
		id: data.id,
		inscriptionId: data.inscriptionId === undefined ? BigInt(0) : BigInt(data.inscriptionId),
		inscriptionAddress: data.inscriptionAddress,
		// globalId: data.globalId === undefined ? BigInt(0) : BigInt(data.globalId),
		blockTimestamp: data.blockTimestamp === undefined ? BigInt(0) : BigInt(data.blockTimestamp),
		tick: data.tick,
		max: data.max === undefined ? BigInt(0) : BigInt(data.max),
		limit: data.limit === undefined ? BigInt(0) : BigInt(data.limit),
		tokenId: data.tokenId === undefined ? BigInt(0) : BigInt(data.tokenId),
		totalSupply: data.totalSupply === undefined ? BigInt(0) : BigInt(data.totalSupply),
		owner: data.owner === undefined ? "0x0" : data.owner,
		transactions: data.transactions === undefined ? "" : data.transactions,
	}
}

export const parseQueryHoldersData = (data: any): HoldersData => {
	return {
		inscriptionAddress: data.inscriptionAddress,
		ownerAddress: data.address,
		amount: BigInt(data.amount),
	}
}

export const parseQueryTransactonsData = (data: any): TransactionsData => {
	return {
		inscriptionAddress: data.inscriptionAddress,
		transactionHash: data.transactionHash,
		tokenId: BigInt(data.tokenId),
		from: data.from,
		to: data.to,
		blockTimestamp: BigInt(data.blockTimestamp)
	}
}

export const getBlockTimestamp = async (chainId: number) => {
	const network = getNetwork(chainId);
	const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
	return (await provider.getBlock('latest')).timestamp;
}

export const stringToUtf8Array = (str: string) => {
	const result = [];
	for(var i = 0; i < str.length; i+=2) {
		result.push(parseInt(str.substring(i, i + 2), 16));
	}
	return Uint8Array.from(result)
}

export const getErrorMessage = (message: string) => {
	if(message.includes("User rejected the request")) {
		return "You have canceled";
	}
	const start = message.indexOf("reason") + 8;
	const end = message.indexOf("Contract Call") - 1;
	return message.slice(start, end);
}