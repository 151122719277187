import { Chain } from "wagmi";
import { PROJECT_ID } from "../config";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

export default function WalletConnectConnectorInstance(chains: Chain[]) {

	return new WalletConnectConnector({
		chains,
		options: {
			projectId: PROJECT_ID,
			showQrModal: true,
		},
	});
}