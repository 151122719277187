import { FC } from "react";
import { DeployData, TokenListProps } from "../utils/types";
import { DARK_THEME, DECIMALS, PROTOCOL } from "../config";
import { BigNumber, ethers } from "ethers";
import toast from "react-hot-toast";
import { addCommas, formatAddress, formatDate, toString } from "../utils/common";
import intl from "../utils/intl";
import LinkTo from "./LinkTo";
import { useAccount } from "wagmi";
import { useStore } from "../hooks/useStore";
import FercFamilyLogo from "./FercFamilyLogo";

export const mintPercent = (item: DeployData) => {
	// console.log("total supply", BigNumber.from(item.totalSupply).mul(1000000).div(BigNumber.from(item.max).div(BigNumber.from(item.limit))).div(100).toString());
	const percent = BigNumber.from(item.totalSupply).mul(1000000).div(BigNumber.from(item.max).div(BigNumber.from(item.limit))).div(100);
	// console.log((percent.toNumber() / 10000).toString());
	return percent.toNumber() / 100;
}

const TokenList: FC<TokenListProps> = ({
	list,
	showType,
	scanUrl,
	symbol,
	// version,
	factory,
	currentAddress,
	setMintItem,
	checkTick,
}) => {
	const { address } = useAccount();
	// const theme = useStore((state) => state.theme);
	// console.log('theme', theme);
	
	return (
		<div className="">
			<table className="table w-full">
				<thead>
					<tr>
						{/* <td className="text-center text-lg">#</td> */}
						<td className="text-lg">{intl.get("tick")}</td>
						<td className="text-lg">{intl.get("contract")}</td>
						<td className="text-lg">{intl.get("hc-lpm")}</td>
						<td className="text-lg">{intl.get("limit")}</td>
						<td className="text-center text-lg">{intl.get("deploy-time")}</td>
						<td className="text-center text-lg">{intl.get("holders")}</td>
						<td className="text-center text-lg">{intl.get("progress")}</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{list && list.map((item, key) => 
							<tr key={key}>
								{/* <td className="text-center">
									{toString(item.inscriptionId)}
								</td> */}
								<td>
									<div className="flex text-lg ml-2">
										<div>{item.tick}</div>
										<div>{item.needFerc && <FercFamilyLogo />}</div>
									</div>
									<div className="badge badge-sm bg-base-300 text-base-content border-0">{PROTOCOL}</div>
								</td>
								<td>
									<div className="flex">
										{item && formatAddress(item.inscriptionAddress)}
										<LinkTo copyText={item.inscriptionAddress} url={`${scanUrl}/token/${item.inscriptionAddress}#balances`} />
									</div>
								</td>
								<td>
									<div>
										{addCommas(ethers.utils.formatUnits(item.max.toString(), DECIMALS))}
									</div>
								</td>
								<td>
									<div>
										{addCommas(ethers.utils.formatUnits(item.limit.toString(), DECIMALS))}
									</div>
								</td>
								<td>
									<div className='text-center'>
										{formatDate(BigNumber.from(item.blockTimestamp).mul(1000).toNumber())}
									</div>
								</td>
								<td>
									<div className='text-center'>
										{item.holders?.toString()}
									</div>
								</td>
								<td>
									<div className="text-sm text-center">
										{mintPercent(item).toString() + "%"}
									</div>
									<div className='bg-base-300 h-2 rounded-full'>
										<progress className="progress progress-secondary float-left" value={mintPercent(item).toString()} max="100"></progress>
									</div>
								</td>
								<td>
									<label
										htmlFor="mintModal"
										className="btn btn-outline btn-secondary btn-sm"
										onClick={async () => {
											if(address === undefined) {
												toast.error("Please connect wallet!");
											} else {
												setMintItem(item);
												checkTick(item.tick, 1)
											}
										}}
									>
										{mintPercent(item) < 100 && address !== undefined ? "Mint" : "Enter"}
									</label>
								</td>
							</tr>
					)}
				</tbody>
			</table>

		</div>)
}

export default TokenList;