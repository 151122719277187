import { FC, useEffect, useState } from "react";
import { PaginationProps } from "../utils/types";
import { toNumber } from "../utils/common";
import toast, { Toaster } from "react-hot-toast";

const Pagination: FC<PaginationProps> = ({
	totalInscriptions,
	pageSize,
	onPageClick
}) => {
	const [pages, setPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		const pages = Math.floor((totalInscriptions - 1) / pageSize) + 1;
		setPages(pages)
	}, [pageSize, totalInscriptions]);

	const next = () => {
		if (currentPage + 1 <= pages) {
			changePage(currentPage + 1)
		}
	}

	const last = () => {
		if (currentPage - 1 >= 1) {
			changePage(currentPage - 1)
		}
	}
	const changePage = (page: any) => {
		if (!page) {
			setCurrentPage(1);
			onPageClick(1);
		} else if (currentPage - 1 >= 1 || currentPage + 1 <= pages) {
			page = toNumber(page)
			if (page > 500) {
				toast.error('Only can list 500 pages')
				setCurrentPage(500);
				onPageClick(500);
			} else {
				setCurrentPage(page);
				onPageClick(page);

			}
		}
	}

	return (
		<>
			<div className="btn-group">
				<button className="btn" onClick={() => {
					last()
				}}>«</button>
				<button className="btn">Page <input type="text" value={currentPage} className="input input-sm w-8 h-6 ml-2 p-0" onChange={(e) => changePage(e.target.value)} />/{pages}</button>
				<button className="btn" onClick={() => {
					next()
				}}>»</button>
			</div>
			<Toaster toastOptions={{
				duration: 2000,
			}} />
		</>)
}

export default Pagination;