import { create } from "zustand";
import { DARK_THEME } from "../config";

interface StoreType {
	theme: string;
	setTheme: (theme: string) => void;
}

export const useStore = create<StoreType>(set => ({
	theme: DARK_THEME,
	setTheme: (theme) => set({ theme}),
}));
