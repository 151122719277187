import { FC } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { TooltipProps } from "../utils/types";

const Tooltip: FC<TooltipProps> = ({
	message
}) => {
	return(
	<>
		<div className="tooltip -mt-2 text-left tooltip-right z-100" data-tip={message}>
			<div className="label"><AiFillQuestionCircle /></div>
		</div>
	</>
	)
}

export default Tooltip;