import { CHAINS, INFURA_API_KEY, THEGRAPH_URL } from './config';
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { publicProvider } from "wagmi/providers/public";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask'
import { infuraProvider } from 'wagmi/providers/infura';
// import Web3AuthConnectorInstance from "./utils/Web3AuthConnectorInstance";
// import WalletConnectConnectorInstance from "./utils/WalletConnectorInstance";
import Router from "./router/Routers";
import Web3AuthNoModalInstance from "./utils/Web3AuthNoModalInstance";
import WalletConnectConnectorInstance from './utils/WalletConnectorInstance';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  CHAINS,
  [infuraProvider({
		apiKey: INFURA_API_KEY
	}), publicProvider()],
)

function App() {
	const wagmiConfig = createConfig({
		autoConnect: true,
		connectors: [
			// Inject connector #0
			new InjectedConnector({chains, options: {name: "Injected", shimDisconnect: true}}),

			// Metamask connector #1
			new MetaMaskConnector({chains, options: {shimDisconnect: true}}),

			// WalletConnect connector #2
			WalletConnectConnectorInstance(chains) as any,

			// Web3AuthConnectorInstance(chains) as any,
			Web3AuthNoModalInstance(chains, "twitter") as any, // #3
			Web3AuthNoModalInstance(chains, "google") as any,  // #4
			Web3AuthNoModalInstance(chains, "apple") as any,   // #5
			Web3AuthNoModalInstance(chains, "wechat") as any,  // #6
		],
		publicClient,
		webSocketPublicClient,
	});

	const graphClient = new ApolloClient({
		uri: THEGRAPH_URL as string,
		cache: new InMemoryCache(),
	});

	return (
		<>
		<WagmiConfig config={wagmiConfig}>
			<Router graphClient={graphClient} />
		</WagmiConfig>
		</>
	);  
}

export default App;
