import { FC, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { useAccount, useNetwork } from "wagmi";
import { CONFIRMATIONS, DECIMALS, FORMAT_NUMBER_DECIMAL, FREEZE_TIME, MINT_GAS_FEE_BUY_FERC, MINT_GAS_FEE_NO_BUY_FERC, MIN_FERC_HOLD, PAGE_SIZE, SHARE_TEXT, SUPPORT_CHAIN_ID, getNetwork } from "../config";
import { DeployData, HoldersData, NetworkData, TransactionsData } from "../utils/types";
import { BigNumber, ethers } from "ethers";
import toast, { Toaster } from 'react-hot-toast';
import { queryDeployDetailsByAddress, queryHolders, queryTransactions } from "../utils/graphQuery";
import { addCommas, formatAddress, getBlockTimestamp, getErrorMessage, parseQueryDeployData, parseQueryHoldersData, parseQueryTransactonsData, toNumber } from "../utils/common";
import { writeContract, readContract, waitForTransaction } from "@wagmi/core";
import FERC721ABI from '../abi/FERC721.json';
import FERC20ABI from '../abi/FERC20.json';
import { getEthFercExactOutputSingle, getGasPrice } from "../utils/uniswap";
import intl from "react-intl-universal";
import HolderList from "./HolderList";
import Pagination from "./Pagination";
import Transactions from "./Transactions";
import LinkTo from "./LinkTo";
import useDevice from "../hooks/useDevice";
import { TwitterShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaXTwitter } from "react-icons/fa6";
import Countdown from "react-countdown";
import { mintPercent } from "./TokenList";
import { SiOpensea } from "react-icons/si";
import { IoCopy } from "react-icons/io5";
import PaginationNext from "./PaginationNext";
import SmartInscriptionFactoryABI from '../abi/SmartInscriptionFactory.json';

interface InscDetailsProps {
	graphClient: ApolloClient<NormalizedCacheObject>;
}

const InscDetails: FC<InscDetailsProps> = ({
	graphClient
}) => {
	const params = useParams();
	const [network, setNetwork] = useState({} as NetworkData);
	const [mintItem, setMintItem] = useState({} as DeployData);
	const [minting, setMinting] = useState(false);
	const [myBalance, setMyBalance] = useState(0);
	const [totalSupply, setTotalSupply] = useState(0);
	const [fercLoaded, setFercLoaded] = useState(false);
	const [showMintButton, setShowMintButton] = useState(false);
	const [ethSwapToFerc, setEthSwapToFerc] = useState(BigInt(0));
	const [mintGasFee, setMintGasFee] = useState(MINT_GAS_FEE_NO_BUY_FERC);
	const [gasPrice, setGasPrice] = useState(BigInt(0));
	const [waitSeconds, setWaitSeconds] = useState(FREEZE_TIME);
	const [holdersList, setHoldersList] = useState([] as Array<HoldersData>);
	const [transactionsList, setTransactionsList] = useState([] as Array<TransactionsData>);
	const [SVGImage, setSVGImage] = useState("");
	const [percent, setPercent] = useState("0");
	const [currentPage4Holders, setCurrentPage4Holders] = useState(1);
	const [pageSize4Holders, setPageSize4Holders] = useState(PAGE_SIZE);
	const [currentPage4Trans, setCurrentPage4Trans] = useState(1);
	const [pageSize4Trans, setPageSize4Trans] = useState(PAGE_SIZE);
	const [mintTip, setMintTip] = useState(BigInt(0));
	const [mintTipLoaded, setMintTipLoaded] = useState(false);

	const isMobile = useDevice();

	const { chain } = useNetwork();
	const { address: currentAddress } = useAccount();

	const SHARE_URL = window.location.protocol.replace(/:/g, '') + "://" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/insc/"

	useEffect(() => {
		if (ethers.utils.isAddress(params.inscriptionAddress as string)) initializeNetworks();
		else toast.error("Unavailable address: " + params.inscriptionAddress as string);
	}, [currentAddress]);

	useEffect(() => {
		if (network.contractAddress) {
			readContract({
				address: network.contractAddress as any,
				abi: SmartInscriptionFactoryABI,
				functionName: 'mintTip',
				args: []
			}).then((mintTipValue) => {
				setMintTip(mintTipValue as bigint);
				setMintTipLoaded(true)
			});


		}
	}, [network.contractAddress]);

	useEffect(() => {
		if (waitSeconds === 0 && fercLoaded && mintTipLoaded) setShowMintButton(true);
		else setShowMintButton(false);
	}, [waitSeconds, fercLoaded]);


	const initializeNetworks = () => {
		if (currentAddress === undefined) return;

		if (chain) {
			const chainId = chain.id;
			if (chainId !== SUPPORT_CHAIN_ID) {
				toast.error("Only support chain id: " + SUPPORT_CHAIN_ID);
				return;
			}
			const _network = getNetwork(chainId);
			setNetwork({
				id: chainId,
				name: chain.name,
				symbol: chain.nativeCurrency.symbol,
				decimals: chain.nativeCurrency.decimals,
				scanUrl: _network?.scanUrl as string,
				network: _network?.network as string,
				openseaUrl: _network?.openseaUrl as string,
				contractAddress: _network?.contractAddress as string,
				wethAddress: _network?.wethAddress as string,
				fercAddress: _network?.fercAddress as string,
				rpc: _network?.rpc as string,
				bgColor: _network?.bgColor as string,
				foreColor: _network?.foreColor as string,
			} as NetworkData)
			if (_network !== undefined) loadData(params.inscriptionAddress as string, _network?.fercAddress as string);
			else toast.error("Network loaded fail");
		}
	}

	const loadData = async (inscriptionAddress: string, fercAddress: string) => {
		const result = await graphClient.query({
			query: gql`${queryDeployDetailsByAddress}`,
			variables: {
				inscriptionAddress,
			}
		});
		if (result.error !== undefined || result.data.deploy === undefined) {
			toast.error("Unavailable address: " + inscriptionAddress as string);
			return;
		}

		if (result.data.deploy !== null) {
			const parsedData = parseQueryDeployData(result.data.deploy);
			if (parsedData.inscriptionAddress !== undefined && parsedData.totalSupply !== undefined) {
				setMintItem(parsedData);
				setPercent(mintPercent(parsedData).toString());
				checkInscriptionBalance(inscriptionAddress as string);
				if (parsedData.needFerc) checkFercBalance(fercAddress);
				else setFercLoaded(true);
				getGasPrice(chain?.id as number).then(res => setGasPrice(BigInt(res)));
				getLastMintTime(inscriptionAddress);
				getHoldersList(inscriptionAddress, currentPage4Holders);
				getTransactionsList(inscriptionAddress, currentPage4Trans);
				getTokenURI(inscriptionAddress as string);
			}
		}
	}



	const checkInscriptionBalance = (inscriptionAddress: string) => {
		readContract({
			address: inscriptionAddress as `0x{string}`,
			abi: FERC721ABI,
			functionName: 'balanceOf',
			args: [currentAddress]
		}).then(res => setMyBalance(toNumber(res)));

		readContract({
			address: inscriptionAddress as `0x{string}`,
			abi: FERC721ABI,
			functionName: "totalSupply",
			args: []
		}).then(res => setTotalSupply(toNumber(res)));
	}

	const checkFercBalance = (fercAddress: string) => {
		readContract({
			address: fercAddress as `0x{string}`,
			abi: FERC721ABI,
			functionName: 'balanceOf',
			args: [currentAddress]
		}).then((res: any) => {
			// get current exchange rate for 100 fercs
			if (BigInt(res) < MIN_FERC_HOLD) {
				setMintGasFee(MINT_GAS_FEE_BUY_FERC);
				getEthFercExactOutputSingle(chain?.id as number, MIN_FERC_HOLD).then(res => {
					setEthSwapToFerc(BigInt(Math.floor(res * 1.2)));
					setFercLoaded(true);
				});
			} else {
				setFercLoaded(true);
				setEthSwapToFerc(BigInt(0));
			}
		});
	}

	const getLastMintTime = (inscriptionAddress: string) => {
		readContract({
			address: inscriptionAddress as `0x{string}`,
			abi: FERC721ABI,
			functionName: 'lastMintTimestamp',
			args: [currentAddress],
		}).then(async (res: any) => {
			if (toNumber(res) > 0) {
				const blockTimestamp = await getBlockTimestamp(chain?.id as number);
				const pastSeconds = Math.floor(blockTimestamp - toNumber(res));
				setWaitSeconds(FREEZE_TIME - pastSeconds > 0 ? FREEZE_TIME - pastSeconds : 0);
			} else setWaitSeconds(0);
		});
	}

	const getHoldersList = (inscriptionAddress: string, page: number) => {
		graphClient.query({
			query: gql`${queryHolders}`,
			variables: {
				inscriptionAddress: inscriptionAddress,
				first: pageSize4Holders,
				skip: pageSize4Holders * (page - 1)
			}
		}).then((result: any) => {
			let _list = [] as Array<HoldersData>;
			if (result.error === undefined && result.data.holders[0] !== undefined) {
				for (let i = 0; i < result.data.holders.length; i++) {
					_list.push(parseQueryHoldersData(result.data.holders[i]));
				}
			}
			setHoldersList(_list);
		})
	}

	const getTransactionsList = (inscriptionAddress: string, page: number) => {
		graphClient.query({
			query: gql`${queryTransactions}`,
			variables: {
				inscriptionAddress: inscriptionAddress,
				first: pageSize4Trans,
				skip: pageSize4Trans * (page - 1)
			}
		}).then((result: any) => {
			let _list = [] as Array<TransactionsData>;
			if (result.error === undefined && result.data.transfers[0] !== undefined) {
				for (let i = 0; i < result.data.transfers.length; i++) {
					_list.push(parseQueryTransactonsData(result.data.transfers[i]));
				}
			}
			setTransactionsList(_list);
		})
	}

	const getTokenURI = (inscriptionAddress: string) => {
		readContract({
			address: inscriptionAddress as `0x{string}`,
			abi: FERC721ABI,
			functionName: "tokenURI",
			args: [1],
		}).then((result: any) => {
			if (result !== "") {
				result = result.substring(29); // delete the header: data:application/json;base64,
				const svg = JSON.parse(atob(result)).image;
				setSVGImage(svg);
			}
		})
	}

	const mint = async () => {
		if (mintItem.tick === "") return;
		setMinting(true);
		if (!mintItem) return;
		try {
			if (mintTip > 0 && waitSeconds > 0) {
				const approved = await readContract({
					address: network.fercAddress as `0x{string}`,
					abi: FERC20ABI,
					functionName: 'allowance',
					args: [currentAddress, mintItem.inscriptionAddress],
				}) as bigint;
				if (approved < mintTip) {
					const { hash } = await writeContract({
						address: network.fercAddress as `0x{string}`,
						abi: FERC20ABI,
						functionName: 'approve',
						args: [mintItem.inscriptionAddress, mintTip]
					});
					const result = await waitForTransaction({ hash })
					if (result.status !== 'success') {
						toast.error("Approve Error");
						return
					}
				}
			}

			const { hash } = await writeContract({
				address: mintItem.inscriptionAddress as `0x{string}`,
				abi: FERC721ABI,
				functionName: "mint",
				args: [currentAddress],
				value: ethSwapToFerc.toString() as any,
			});

			waitForTransaction({ hash, confirmations: CONFIRMATIONS }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Mint successfully");
				} else {
					toast.error("Mint Error");
				}
				setWaitSeconds(FREEZE_TIME);
				loadData(params.inscriptionAddress as string, network.fercAddress);
				// (document.getElementById("mintModal2") as HTMLInputElement).checked = false;
				setMinting(false);
			})
		} catch (err: any) {
			setMinting(false);
			toast.error(getErrorMessage(err.message));
		}
	}

	const Completionist = () => <span>Mint Cirth</span>;

	interface CountDownProps {
		minutes: number;
		seconds: number;
		completed: boolean;
	}

	const renderer: FC<CountDownProps> = ({
		minutes,
		seconds,
		completed
	}) => {
		if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {
			// Render a countdown
			return <span>Wait for {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</span>;
		}
	};

	return (
		<>
			{mintItem.inscriptionAddress &&
				<div className={`${isMobile ? "mt-20" : "mt-8"} w-full md:p-24 p-2`}>
					<div className="flex justify-between">
						{!isMobile &&
							<div className="md:w-[30%] p-3">
								{SVGImage !== "" &&
									<div>
										<img className="w-[260px] rounded-lg" src={SVGImage} alt="Example Imprint" />
									</div>}
							</div>}
						<div className={`md:w-[70%] w-full p-3 ${isMobile ? "text-sm" : "text-md"}`}>
							<label className="flex justify-between mb-3">
								<span className="w-[40%] text-left mt-2">{intl.get("tick")}</span>
								<span className="w-[60%] text-right font-bold text-lg">{mintItem.tick}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{intl.get("contract")}
									{/* <Tooltip
										message={intl.get("tooltip-contract")}
									/> */}
								</span>
								<span className="w-[60%] text-right font-bold">
									{mintItem.inscriptionAddress && formatAddress(mintItem.inscriptionAddress)}
								</span>
								<span className="flex">
									<LinkTo copyText={mintItem.inscriptionAddress} url={`${network.scanUrl}/address/${mintItem.inscriptionAddress}`} />
								</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{intl.get("hard-cap")}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.max && addCommas(ethers.utils.formatUnits(mintItem.max.toString(), DECIMALS))}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{intl.get("limit")}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.limit && addCommas(ethers.utils.formatUnits(mintItem.limit.toString(), DECIMALS))}</span>
							</label>

							{/* 
							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{intl.get("free-mint-interval")}
								<Tooltip 
									message={intl.get("tooltip-free-mint-interval")}
								/></span>
								<span className="w-[60%] text-right font-bold">{secondsToHMS(600)}</span>
							</label> */}

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{intl.get("total-mint")}</span>
								<span className="w-[60%] text-right font-bold">{totalSupply && addCommas(ethers.utils.formatUnits(totalSupply, DECIMALS))}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{intl.get("my-balance")}</span>
								<span className="w-[60%] text-right text-secondary font-bold">{myBalance && addCommas(ethers.utils.formatUnits(myBalance, DECIMALS))}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{"Gas Price"}</span>
								<span className="w-[60%] text-right text-secondary font-bold">~{parseFloat(ethers.utils.formatUnits(gasPrice, 9)).toFixed(2)} Gwei</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">{"Gas Fee"}</span>
								<span className="w-[60%] text-right text-secondary font-bold">~{parseFloat(ethers.utils.formatUnits(mintGasFee * gasPrice, 18)).toFixed(FORMAT_NUMBER_DECIMAL)} ETH</span>
							</label>

							{ethSwapToFerc > BigInt(0) &&
								<div className="mb-3">
									<label className="flex justify-center">
										<span className="w-[40%] text-left">{intl.get("swap-for-ferc", { amount: ethers.utils.formatUnits(MIN_FERC_HOLD, 18) })}
											{/* <Tooltip
											message={intl.get("tooltip-swap-for-ferc", { amount: ethers.utils.formatUnits(MIN_FERC_HOLD, 18) })}
										/> */}
										</span>
										<span className="w-[60%] text-right font-bold">~{parseFloat(ethers.utils.formatUnits(ethSwapToFerc, 18)).toFixed(FORMAT_NUMBER_DECIMAL) + " " + network.symbol}</span>
									</label>
									<label className="flex text-base-content text-sm">
										{"Copy ferc addr: " + formatAddress(network.fercAddress)}
										<LinkTo copyText={`${network.fercAddress}`} />
									</label>
								</div>
							}
							<label className='flex justify-center mb-3'>
								<span className="w-[40%] text-left">{intl.get("progress")}</span>
								<span className="w-[60%] text-right text-secondary font-bold">{percent + "%"}</span>
							</label>

							<label className='flex justify-center mb-3'>
								<progress className="progress progress-secondary float-left" value={percent} max="100"></progress>
							</label>

						</div>
					</div>
					{currentAddress === undefined ? <div className="mt-24 text-center w-full">{"Please connect wallet"}</div> :
						waitSeconds === FREEZE_TIME ? <></> :
							showMintButton ?
								<button className={`w-[96%] ml-[2%] md:w-full md:ml-0 btn ${minting ? 'loading btn-disabled' : 'btn-secondary'}`} onClick={() => mint()}>{intl.get("mint")}</button>
								: (mintTipLoaded && mintTip > BigInt(0) && waitSeconds > 0) ? <div className="btn-group w-full"><button className={`w-[60%] ml-[2%] md:w-[80%] md:ml-0 btn btn-disable`}><Countdown date={Date.now() + waitSeconds * 1000} onComplete={() => setWaitSeconds(0)} renderer={renderer} /></button><label className="btn btn-secondary w-[35%] md:w-[20%]" htmlFor="my-modal-6">mint now</label></div> : <button className={`w-[96%] ml-[2%] md:w-[100%] md:ml-0 btn btn-disable`}><Countdown date={Date.now() + waitSeconds * 1000} onComplete={() => setWaitSeconds(0)} renderer={renderer} /></button>}
					<div className="w-full flex flex-row justify-center mt-5 mb-2 md:my-5 gap-10">

						<TwitterShareButton url={SHARE_URL + mintItem.inscriptionAddress} title={SHARE_TEXT + " $" + mintItem.tick}>
							<div className="w-5 h-5 p-1 rounded-full bg-base-content cursor-pointer">
								<FaXTwitter className='text-xs text-primary-content' />
							</div>
						</TwitterShareButton>

						<CopyToClipboard
							text={SHARE_URL + mintItem.inscriptionAddress}
							onCopy={() => {
								toast.success("Copied");
							}}
						>
							<IoCopy className='text-xl text-base-content cursor-pointer' />
						</CopyToClipboard>

						<a href={`${network.openseaUrl}/collection/${network.network}/${mintItem.inscriptionAddress}`}>
							<SiOpensea className='text-xl text-base-content cursor-pointer' />
						</a>
					</div>

					<div className="divider" />

					{/* Holder list */}
					<div>
						<HolderList
							hodlersList={holdersList}
							totalSupply={mintItem.totalSupply as BigInt}
						/>
						<div className="overflow-x-auto m-auto text-center">

							{/* pagination */}
							{toNumber(mintItem.holders) > PAGE_SIZE ? <div className="mt-5 mb-1	">
								<PaginationNext
									pageSize={pageSize4Holders}
									onPageClick={(page: number) => { setCurrentPage4Holders(page); getHoldersList(mintItem.inscriptionAddress, page); }}
									totalInscriptions={toNumber(mintItem.holders)}
								/>
							</div> : <></>
							}
						</div>
					</div>

					<div className="divider" />

					{/* Transactions */}
					<div>
						<Transactions
							transactionsList={transactionsList}
						/>
						<div className="overflow-x-auto m-auto text-center md:mb-0 mb-40">

							{/* pagination */}
							{toNumber(mintItem.transactions) > PAGE_SIZE ? <div className="mt-5 mb-1	">
								<PaginationNext
									pageSize={pageSize4Trans}
									onPageClick={(page: number) => { setCurrentPage4Trans(page); getTransactionsList(mintItem.inscriptionAddress, page); }}
									totalInscriptions={toNumber(mintItem.transactions)}
								/>
							</div> : <></>
							}
						</div>
					</div>

				</div>
			}

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 2000,
			}} />
			<input type="checkbox" id="my-modal-6" className="modal-toggle" />
			<div className="modal modal-bottom sm:modal-middle">
				<div className="modal-box">
					<h3 className="font-bold text-lg">Confirm</h3>
					<p className="py-4">5 ferc in your wallet will be burned, are you sure?</p>
					<div className="modal-action">
						<label htmlFor="my-modal-6" className="btn" onClick={() => mint()}>Yay!</label>
						<label htmlFor="my-modal-6" className="btn">No</label>
					</div>
				</div>
			</div>

		</>
	)
}

export default InscDetails;