import { FC, useEffect, useState } from "react";
import { MintProps } from "../utils/types";
// import { toNumber } from "../utils/common";
import intl from "../utils/intl";
import { Link } from "react-router-dom";
import { MAX_TICK_SIZE, MIN_TICK_SIZE } from "../config";

const Mint: FC<MintProps> = ({
	canMint,
	checkTick,
	// mintRepeat,
	// setMintRepeat,
	mintItem,
}) => {
	const [mintTick, setMintTick] = useState("");
	// const [canRepeat, setCanRepeat] = useState(false);

	useEffect(() => {
		// if(mintItem && mintItem.freezeTime !== undefined) {
		// const freezeTime = toNumber(mintItem.freezeTime);
		// setCanRepeat(freezeTime === 0);
		// if(freezeTime > 0) setMintRepeat(1);
		// }
		setMintTick(mintItem?.tick ? mintItem.tick : "");
	}, [mintItem]);

	const closeModal = () => (document.getElementById("mintModal") as HTMLInputElement).checked = false;

	return (
		<>
			<div>
				<input type="checkbox" id="mintModal" className="modal-toggle" />
				<label htmlFor="mintModal" className="modal cursor-pointer">
					<label className="modal-box" htmlFor="">
						<h3 className="font-bold text-lg">{intl.get("mint")}</h3>
						<div className="divider" />
						<label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={() => closeModal()}>✕</label>
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-right">{intl.get("tick")}</span>
							<input
								type="text"
								value={mintTick}
								placeholder={`${MIN_TICK_SIZE} to ${MAX_TICK_SIZE} characters like 'abcd'...`}
								className="input input-bordered w-[60%]"
								onChange={(e) => setMintTick(e.target.value)}
								onBlur={() => checkTick(mintTick)}
							/>
						</div>

						{/* {canRepeat &&
					<div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-right">{intl.get("rollup-mint")}</span>
						<input 
							type="text" 
							value={mintRepeat} 
							placeholder={`Max ${mintRepeat}`} 
							className="input input-bordered w-[60%]" 
							onChange={(e) => setMintRepeat(parseInt(e.target.value === '' ? '0' : e.target.value))} 
							onBlur={() => checkTick(mintTick, mintRepeat)}
						/>
					</div>} */}
						<div className="modal-action">
							<label className={`btn btn-outline ${canMint ? 'btn-secondary' : 'btn-disabled'}`}>
								<Link to={"/insc/" + mintItem?.inscriptionAddress}>{intl.get("next")}</Link></label>
						</div>
					</label>
				</label>
			</div>
		</>)
}

export default Mint;