import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { MAX_TICK_SIZE, MIN_TICK_SIZE, PAGE_SIZE, SUPPORT_CHAIN_ID, ZERO_ADDRESS, getNetwork, testTicks } from '../config';
import FERC721ABI from '../abi/FERC721.json';
import { readContract } from '@wagmi/core'
import { FiSearch } from "react-icons/fi";
import toast, { Toaster } from 'react-hot-toast';
import { DeployData, NetworkData } from '../utils/types';
import TokenList from './TokenList';
import Deploy from './Deploy';
import Mint from './Mint';
import { parseQueryDeployData, toNumber } from '../utils/common';
import Pagination from './Pagination';
import intl from '../utils/intl';
import { queryDeployDetailsByTick, queryDeployList, queryDeployListByType, querySearchDeployList, queryTotalInscriptions } from '../utils/graphQuery';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import TokenCardList from './TokenCardList';
import useDevice from '../hooks/useDevice';
import { IoRefresh } from 'react-icons/io5';

interface HomeProps {
	graphClient: ApolloClient<NormalizedCacheObject>;
}

const Home: FC<HomeProps> = ({
	graphClient
}) => {
	const [showType, setShowType] = useState(0); // 0- all, 1- in-progress, 2- ended
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const [searchKey, setSearchKey] = useState("");
	const [list, setList] = useState(Array<DeployData>);
	const [network, setNetwork] = useState({} as NetworkData);
	const [loading, setLoading] = useState(false);
	const [mintItem, setMintItem] = useState({} as DeployData);
	const [canMint, setCanMint] = useState(false);
	const [totalInscriptions, setTotalInscriptions] = useState(0);

	const { chain } = useNetwork();
	const { address } = useAccount();

	const abortSignalRef = useRef(new AbortController());

	useEffect(() => {
		// setContractVersion(version);
		if (network !== undefined) load();
	}, [network]);

	useEffect(() => {
		if (chain) {
			const chainId = chain.id;
			if(chainId !== SUPPORT_CHAIN_ID) {
				toast.error("Only support chain id: " + SUPPORT_CHAIN_ID);
				return;
			}
			const _network = getNetwork(chainId);
			setNetwork({
				id: chainId,
				name: chain.name,
				symbol: chain.nativeCurrency.symbol,
				decimals: chain.nativeCurrency.decimals,
				scanUrl: _network?.scanUrl as string,
				openseaUrl: _network?.openseaUrl as string,
				contractAddress: _network?.contractAddress as string,
				wethAddress: _network?.wethAddress as string,
				fercAddress: _network?.fercAddress as string,
				rpc: _network?.rpc as string,
				bgColor: _network?.bgColor as string,
				foreColor: _network?.foreColor as string,
			} as NetworkData)
			if (_network !== undefined) load();
		}
	}, [chain]);

	const load = async () => {
		try {
			setLoading(true);
			// get max inscription id
			const result = await graphClient.query({
				query: gql`${queryTotalInscriptions}`,
				variables: {},
				context: {
					fetchOptions: {
						get signal() {
							return abortSignalRef.current.signal;
						},
					}
				}
			});
			// abortSignalRef.current.abort();

			if (result.error !== undefined || result.data.deploys[0] === undefined) {
				toast.error('Something is wrong!');
				setLoading(false);
				return;
			}
			const count = toNumber(result.data.deploys[0].inscriptionId);
			setTotalInscriptions(count);

			await loadInscriptions(currentPage);
			setSearchKey("");
			setLoading(false);
		} catch (err: any) {
			if (err.details?.indexOf("stack underflow") !== -1) {
				// toast.error("ERROR: Walletconnect is overload now, please try again later!");
				setLoading(false);
			}
		}
	}

	useEffect(() => {
		loadInscriptions(currentPage);
	}, [showType]);

	const loadInscriptions = useCallback(async (_currentPage: number) => {
		try {
			const list = await getInscriptionList(_currentPage, pageSize)
			// console.log(list)
			setList(list);
			setLoading(false)
		} catch (err: any) {
			console.log(err);
		}
	}, [network, pageSize, showType]);

	const getInscriptionList = async (page: number, pageSize: number): Promise<Array<DeployData>> => {
		let result;
		if (showType === 0) {
			result = await graphClient.query({
				query: gql`${queryDeployList}`,
				variables: {
					first: pageSize,
					skip: pageSize * (page - 1)
				},
				context: {
					fetchOptions: {
						get signal() {
							return abortSignalRef.current.signal;
						},
					}
				}
			});
			// abortSignalRef.current.abort();
		} else { // in-progress
			result = await graphClient.query({
				query: gql`${queryDeployListByType}`,
				variables: {
					first: pageSize,
					skip: pageSize * (page - 1),
					completed: showType === 2
				},
				context: {
					fetchOptions: {
						get signal() {
							return abortSignalRef.current.signal;
						},
					}
				}
			});
			// abortSignalRef.current.abort();
		}

		const res = new Array<DeployData>();
		if (result.error !== undefined || result.data.deploys[0] === undefined) return new Array<DeployData>();
		for (let i = 0; i < result.data.deploys.length; i++) {
			const data = result.data.deploys[i];
			if(!testTicks.includes(data.tick)) res.push(parseQueryDeployData(data));
		}
		return res;
	}

	const getTotalSupply = async (_inscriptionAddress: string): Promise<number> => {
		if (_inscriptionAddress === ZERO_ADDRESS) return 0;
		try {
			const result = await readContract({
				address: _inscriptionAddress as `0x{string}`,
				abi: FERC721ABI,
				functionName: "totalSupply",
			});
			return toNumber(result);
		} catch (err) {
			return 0;
		}
	}

	const searchInstructions = async (key: string) => {
		try {
			const result = await graphClient.query({
				query: gql`${querySearchDeployList}`,
				variables: {
					first: pageSize,
					key
				},
				context: {
					fetchOptions: {
						get signal() {
							return abortSignalRef.current.signal;
						},
					}
				}
			});
			// abortSignalRef.current.abort();

			const inscriptions = new Array<DeployData>();
			setList([]);
			if (result.error !== undefined || result.data.deploys[0] === undefined) return new Array<DeployData>();
			for (let i = 0; i < result.data.deploys.length; i++) {
				const data = result.data.deploys[i];
				inscriptions.push(parseQueryDeployData(data));
			}
			setList(inscriptions);
		} catch (err: any) {
			console.log(err);
		}
	}

	const checkTick = async (tick: string) => {
		// get mint token information
		if (tick.trim() === "" || tick.trim().length < MIN_TICK_SIZE || tick.trim().length > MAX_TICK_SIZE) {
			// toast.error("Please input right tick, make sure the repeat time between 1-10");
			setCanMint(false);
			return;
		};

		try {
			await loadInscriptionByTick(tick);
		} catch (err) {
			setCanMint(false);
			toast.error("Oops! there is something wrong. The tick is not deployed. Please check your network")
		}
	}

	const loadInscriptionByTick = async (tick: string) => {
		try {
			const result = await graphClient.query({
				query: gql`${queryDeployDetailsByTick}`,
				variables: {
					tick
				},
				context: {
					fetchOptions: {
						get signal() {
							return abortSignalRef.current.signal;
						},
					}
				}
			});
			// abortSignalRef.current.abort();

			if (result.error !== undefined || result.data.deploys[0] === undefined) {
				toast.error('Something is wrong!');
				return;
			}
			// console.log("loadInscriptionByTick", result.data.deploys[0]);
			const data = result.data.deploys[0];
			const inscriptionData = {
				...parseQueryDeployData(data),
				totalSupply: BigInt(await getTotalSupply(data.inscriptionAddress)),
			} as DeployData;

			setCanMint(true);
			setMintItem(inscriptionData);
		} catch (err) {
			console.log(err);
		}
	}

	const search = async () => {
		setLoading(true);
		try {
			if (searchKey.trim() !== '') {
				await searchInstructions(searchKey);
			} else {
				await loadInscriptions(currentPage);
			}
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	}

	const clickType = async (type: number) => {
		setShowType(type);
		setLoading(true);
		try {
			// the Graph check complete/in-progress/end
			setCurrentPage(1);
			// await parseInstructions(inscriptions);
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	}

	const isMobile = useDevice();

	return (
		<div className="bg-base-200 h-screen">
			{/* Title  */}
			<div className="hero md:h-[400px] h-[280px] pt-[80px]">
				<div className="hero-content text-center">
					<div className="md:max-w-2xl">
						<h1 className="text-2xl mx-3 font-bold md:leading-relaxed md:text-4xl">{intl.get("introduction")}</h1>
						{/* <h1 className="text-3xl font-bold mt-3">{contractVersion}</h1> */}
						{/* <p className="py-6">{intl.get("introduction")}</p> */}
						<div className="mb-1">
						</div>
						<div className="btn-group mt-5">
							{address !== undefined && 
							<div>
							<label htmlFor="deployModal" className="btn btn-primary btn-outline w-[150px] tracking-widest">{intl.get("deploy")}</label>
							{/* <label
								htmlFor="mintModal"
								className="btn btn-secondary w-[150px] tracking-widest"
							>
								{intl.get("mint")}
							</label> */}
							</div>}
						</div>
					</div>
				</div>
			</div>

			{/* Table of incriptions */}
			<div className="overflow-x-auto w-[98%] md:w-[90%] mx-auto bg-base-300 rounded-lg p-5 text-center">
				{/* search box */}
				<div className='md:flex md:flex-row md:justify-between flex flex-col'>
					<div className="form-control float-left">
						<div className="input-group">
							<input
								type="text"
								placeholder={intl.get("search-by")}
								value={searchKey}
								className="input w-full max-w-xs"
								onKeyUp={(e) => { if (e.code === "Enter") search(); }}
								onChange={(e) => setSearchKey(e.target.value)}
							/>
							<button
								className="btn btn-md m-auto text-base-content"
								onClick={() => search()}
							>
								<FiSearch className='text-2xl' />
							</button>
						</div>
					</div>


					<div className="flex justify-between">
						{/* search type */}
						<div className="btn-group mt-3 float-right md:mt-0 md:mb-4">
							<div className={`btn btn-md ` + (showType === 0 ? "btn-primary" : "")} onClick={() => clickType(0)}>{intl.get("all")}</div>
							<div className={`btn btn-md ` + (showType === 1 ? "btn-primary" : "")} onClick={() => clickType(1)}>{intl.get("in-progress")}</div>
							<div className={`btn btn-md ` + (showType === 2 ? "btn-primary" : "")} onClick={() => clickType(2)}>{intl.get("ended")}</div>
						</div>
						<div 
							className={`mr-5 mt-7 md:mt-4 md:ml-5 md:mr-3 cursor-pointer`} 
							onClick={() => {
								graphClient.clearStore();
								load();
							}}
						>
							<IoRefresh className={`${loading ? "loading-icon" : ""}`}/>
						</div>
					</div>
				</div>

				{/* Table of tokens */}
				{isMobile ? <TokenCardList
					list={list}
					scanUrl={network.scanUrl}
					symbol={network.symbol}
					showType={showType}
					factory={network.contractAddress}
					checkTick={checkTick}
					setMintItem={setMintItem}
					currentAddress={address as string}
				/> : <TokenList
					list={list}
					scanUrl={network.scanUrl}
					symbol={network.symbol}
					showType={showType}
					factory={network.contractAddress}
					checkTick={checkTick}
					setMintItem={setMintItem}
					currentAddress={address as string}
				/>}

				{/* pagination */}
				<div className="mt-5 mb-1	">
					<Pagination
						pageSize={pageSize}
						onPageClick={(page: number) => { setLoading(true); loadInscriptions(page); }}
						totalInscriptions={totalInscriptions}
					/>
				</div>
			</div>

			<div className="h-20 bg-base-200"></div>
			{/* Deploy modal */}
			<Deploy
				network={network}
			/>

			{/* Mint modal: step 1 */}
			<Mint
				canMint={canMint}
				// mintRepeat={mintRepeat}
				mintItem={mintItem}
				checkTick={checkTick}
			// setMintRepeat={setMintRepeat}
			/>

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 2000,
			}} />

			{/* Loading */}
			{loading &&
				<div className="fixed top-0 left-0 w-full h-full bg-black/60 z-50 text-center py-[25%]">
					<div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
						role="status">
						<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
						</span>
					</div>
				</div>}

		</div>)
}

export default Home;
