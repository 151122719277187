import { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Outlet, RouteObject, useRoutes } from 'react-router-dom';
import Home from '../components/Home';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import Header from '../components/Header';
import Footer from '../components/Footer';
import InscDetails from '../components/InscDetails';
import MyAccount from '../components/MyAccount';
import Loading from '../components/Loading';
import Bridge from '../components/Bridge';

interface RoutersProps {
	graphClient: ApolloClient<NormalizedCacheObject>
}

const Layout = () => {
  return (
    <>
      <Outlet />
			<Header defaultConnector={3}/>
			<Footer />
    </>
  );
}

const Routes:FC<RoutersProps> = ({
	graphClient
}) => {
  const routes: RouteObject[] = [{
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          element: (
						<Home graphClient={graphClient} />
          ),
        }, {
          path: '/myaccount',
          element: (
						<MyAccount graphClient={graphClient} />
					)
        }, {
					path: '/insc/:inscriptionAddress',
					element: (
						<InscDetails graphClient={graphClient} />
					)
				}, {
					path: '/bridge',
					element: (
						<Bridge graphClient={graphClient} />
					)
				}, {
					path: '/bridge/:tick/:tokenId',
					element: (
						<Bridge graphClient={graphClient} />
					)
				}
      ],
    },
  ];
  const element = useRoutes(routes);

  return <Suspense fallback={<Loading />}>{element}</Suspense>;
}

const Router:FC<RoutersProps> = ({
	graphClient
}) => {
  return (
    <BrowserRouter>
      <Routes graphClient={graphClient}/>
    </BrowserRouter>
  );
}

export default Router;